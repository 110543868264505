import React from "react";
import Header from "../../components/header/Header";
import Sidebar from "../../components/superAdmin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import ServiceTypeList from "../../components/superAdmin/serviceType/ServiceTypeList";

function ServiceTypeListPage() {
  return (
    <div className="main-wrapper">
      <Header sprAdmin={true} />

      <Sidebar />
      <div className="page-wrapper">
        <ServiceTypeList />
        <Footer />
      </div>
    </div>
  );
}

export default ServiceTypeListPage;
