import React from "react";
import Header from "../../components/user/header/Header";
import Sidebar from "../../components/user/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import GuestList from "../../components/user/GuestList";
import { useLocation } from "react-router-dom";

function GuestListPage() {
  const location = useLocation();
  const ServiceID = location.state?.serviceID;
  return (
    <div className="main-wrapper">
      <Header />

      <Sidebar />
      <div className="page-wrapper">
        <GuestList ServiceID={ServiceID}/>
        <Footer />
      </div>
    </div>
  );
}

export default GuestListPage;
