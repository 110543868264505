import React from "react";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import ServiceTrashList from "../../components/admin/service/ServiceTrashList";
function ServiceTrashPage() {
  return (
    <div className="main-wrapper">
      <Header sprAdmin={true} />

      <Sidebar />
      <div className="page-wrapper">
        <ServiceTrashList />
        <Footer />
      </div>
    </div>
  );
}

export default ServiceTrashPage;
