import React from "react";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import AddUser from "../../components/admin/user/AddUser";

function EditUserPage() {
  const location = useLocation();
  const editData = location.state?.editData;
  const serviceID=null
  return (
    <div className="main-wrapper">
    <Header/>

    <Sidebar />
    <div className="page-wrapper">
      <AddUser  editData={editData} />
      <Footer />
    </div>
  </div>
  )
}

export default EditUserPage