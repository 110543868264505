import React from 'react'
import "../../assets/scss/main.scss"
import Navbar from '../../components/website/Navbar'
import Footer from '../../components/website/Footer'
import Features from '../../components/website/Features'
function FeaturesPage() {
  const frontLink='/pricing'
  const backLink='/about'
  return (
    <div className='mainWrapper'>
       <Navbar/>
        <Features />
        <Footer frontLink={frontLink} backLink={backLink} />
</div>

  )
}

export default FeaturesPage