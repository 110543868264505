import React from "react";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import ServiceList from "../../components/admin/service/ServiceList";
function AdminListPage() {
  return (
    <div className="main-wrapper">
    <Header sprAdmin={true} />

    <Sidebar />
    <div className="page-wrapper">
      <ServiceList />
      <Footer />
    </div>
  </div>
  )
}

export default AdminListPage