import React from 'react'
import "../../assets/scss/main.scss"
import Navbar from '../../components/website/Navbar'
import Footer from '../../components/website/Footer'
import Contact from '../../components/website/Contact'
function ContactPage() {
  const frontLink='/'
  const backLink='/works'
  return (
    <div className='mainWrapper'>
       <Navbar/>
        <Contact />
        <Footer frontLink={frontLink} backLink={backLink} />
</div>

  )
}

export default ContactPage