import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { TbUsersGroup } from "react-icons/tb";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";
import { getManagerDashboard } from "../../../api/AdminRequest";
import { PiUserSwitchLight } from "react-icons/pi";
const Dashboard = () => {
  const [guestCount, setGuestCount] = useState(0);
  const [repeatGuestCount, setRepeatGuestCount] = useState(0);
  const [serviceName, setServiceName] = useState([]);
  const [serviceWiseCount, setServiceWiseCount] = useState([]);
  const [guestToday, setGuestToday] = useState(0);
  console.log("guestLastweek",guestToday);
  const [guestLastWeek, setGuestLastweek] = useState(0);

  const [guestMonthly, setGuestMonthly] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [userName, setUserName] = useState("User");

  const [data, setData] = useState({
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      fill: false,
    },
    dataLabels: {
      enabled: false,
    },
    datasets: {
      id: "apaxcharts-line",
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#3D5EE1", "#70C4CF"],
    borderWidth: 3,
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  });
  const [guestChart1, setGuestChart1] = useState([
    {
      name: "Service",
      data: guestMonthly,
    },
  ]);

  // Student Chart

  const [dataBar, setDataBar] = useState({
    colors: ["#3D5EE1", "#70C4CF"],
    chart: {
      type: "bar",
      height: 350,
      width: "100%",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        categoryGap: "30%",
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#777",
          },
        },
      },
      title: {
        text: "",
        align: "left",
        style: {
          fontSize: "18px",
        },
      },
    },
  });

  const [guestchart2, setGuestChart2] = useState([
    {
      name: "Guest",
      type: "column",
      data: serviceWiseCount,
    },
  ]);

  const iconColor = "#0d6efd";
  const iconSize = "40";

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserName(user);
    const getDetails = async () => {
      try {
        const { data } = await getManagerDashboard();
        if (data?.success) {
          setGuestCount(data?.guestCount[0]?.count);
          setRepeatGuestCount(data?.repeatGuestCount[0]?.count);
          setGuestMonthly(data?.monthlyGuestArray);
          setServiceName(data?.serviceName);
          setServiceWiseCount(data?.daywiseGuestArray);
          setGuestToday(data?.todaysCount[0]?.count);
          setGuestLastweek(data?.guestLastweek[0]?.count);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDetails();
  }, []);
  useEffect(() => {
    setGuestChart2([
      {
        name: "Guest",
        type: "column",
        data: serviceWiseCount,
      },
    ]);
    setGuestChart1([
      {
        name: "Service",
        data: guestMonthly,
      },
    ]);

    setDataBar({
      colors: ["#70C4CF", "#3D5EE1"],
      chart: {
        type: "bar",
        height: 350,
        width: "100%",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          categoryGap: "30%",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      labels: serviceName,

      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: "#777",
            },
          },
        },
        title: {
          text: "",
          align: "left",
          style: {
            fontSize: "18px",
          },
        },
      },
    });
  }, [guestMonthly, serviceWiseCount, serviceName]);

  return (
    <>
      {/* Page Wrapper */}

      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Welcome {userName}!</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admindashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Overview Section */}
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Total Guest</h6>
                    <h3>{guestCount?guestCount:0}</h3>
                  </div>

                  <TbUsersGroup color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Total Repeat Guest</h6>
                    <h3>{repeatGuestCount?repeatGuestCount:0}</h3>
                  </div>

                  <PiUserSwitchLight color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Total Admin</h6>
                    <h3>{adminCount}</h3>
                  </div>
                  <RiAdminLine color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Last Week Guest</h6>
                    <h3>{guestLastWeek?guestLastWeek:0}</h3>
                  </div>
                  <MdOutlineBusinessCenter color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <div className="card-body">
                <div className="db-widgets d-flex justify-content-between align-items-center">
                  <div className="db-info">
                    <h6>Guest Today</h6>
                    <h3>{guestToday?guestToday:0}</h3>
                  </div>
                  <AiOutlineTeam color={iconColor} size={iconSize} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Overview Section */}
        <div className="row">
          <div className="col-md-12 col-lg-6">
            {/* Revenue Chart */}
            <div className="card card-chart">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h5 className="card-title">Guest Monthly View</h5>
                  </div>
                  <div className="col-6">
                    <ul className="chart-list-out">
                      <li>
                        <span className="circle-blue" />
                        Guest
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="apexcharts-area"></div>
                <Chart options={data} series={guestChart1} type="line" />
              </div>
            </div>
            {/* /Revenue Chart */}
          </div>
          <div className="col-md-12 col-lg-6">
            {/* Student Chart */}
            <div className="card card-chart">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h5 className="card-title">Guest Day Wise </h5>
                  </div>
                  <div className="col-6">
                    <ul className="chart-list-out">
                      <li>
                        <span className="circle-green" />
                        Guest
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="apexcharts-area"></div>
                <Chart options={dataBar} series={guestchart2} type="line" />
              </div>
            </div>

            {/* /Student Chart */}
          </div>
        </div>
        {/* <TopServiceList serviceProp={expireAdmin} /> */}
      </div>
      {/* Footer */}

      {/* /Main Wrapper */}
    </>
  );
};

export default Dashboard;
