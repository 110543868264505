import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import about from "../../assets/websiteImg/about.png";
import quick from "../../assets/websiteImg/quick.svg";
import value from "../../assets/websiteImg/value.svg";
import service from "../../assets/websiteImg/service.svg";

 const About = () => {
  return (
    <>
   
      <div className="flex">
        <div className="sidenav w-[107px] flex flex-col  lg:p-[30px] text-[13px] lg:border-r-[1px] lg:border-[#F9F9F963] uppercase font-normal tracking-normal text-left text-[#627D89] lg:items-center lg:gap-[80px] sm:gap-[5] lg:justify-center sm:justify-evenly justify-around  transform ">
          <a href="#" className=" transform -rotate-90">
            Whatsapp
          </a>
          <a href="#" className=" transform -rotate-90">
            Instagram
          </a>
          <a href="#" className=" transform -rotate-90">
            linkedin
          </a>
          <a href="#" className=" transform -rotate-90">
            Facebook
          </a>
   
        </div>
        <div className="container mx-auto flex mt-3  justify-center">
          <div className="lg:flex lg:justify-center justify-center gap-[60px]">
            <div>
              <img
                className="lg:h-[400px] flex items-center lg:ml-[40px] lg:w-[400px] lg:mt-[30px] object-cover"
                alt="aboutimg"
                src={about}
              ></img>
            </div>

            <div>
              <div className="mt-[10px] ">
                <button className="text-white bg-[#D9D9D92E]  w-[146px] h-[42px] rounded-[45px] ">
                  <div className="flex p-1 justify-center gap-2 text-[#62767F] font-[17px]">
                    <AiOutlineInfoCircle className="mt-[3px]" />
                    About
                  </div>
                </button>
              </div>
              <p className="text-[#ABABAB] md:text-[55px] text-[38px] lg:mt-[3px] lg:h-[149px] leading-[72px] font-bold  md:w-[477px]">
                Speciality of {""}
                <span className="text-[#07B1FC]">OnCloudWifi </span>
              </p>
              <p className="text-[#637780] text-[13px] font-normal md:w-[660px]  leading-normal">
              At On Cloud WiFi, we are passionate about connectivity. Our team of experts is dedicated to providing innovative solutions that make connecting to the internet a seamless experience. With years of experience in the industry, we understand the importance of reliable and secure WiFi, and we are here to elevate your connectivity game.
              Connect with us today and experience the On Cloud WiFi advantage!
              </p>

              <div className="md:flex lg:flex  mt-[5px] ">
                <div className="m-2">
                  <img
                    src={quick}
                    className="w-[38px] h-[48px]"
                    alt="quickicon"
                  ></img>
                  <p className="text-[#A6A6A6] text-[15px] font-medium mt-2 ">
                  WiFi Logins Made Easy
                  </p>
                  <p
                    className="w-[215px] text-[#939393] text-[12px] font-normal mt-2
 "
                  >
                   Enjoy a simplified login experience with On Cloud WiFi. We make connecting to the internet a breeze for both you and your customers.
                  </p>
                </div>
                <div className="m-2">
                  <img
                    src={value}
                    className="w-[38px] h-[48px]"
                    alt="valueicon"
                  ></img>
                  <p className="text-[#A6A6A6] text-[15px] font-medium mt-2">
                  Secure Data Management
                  </p>
                  <p className="w-[215px] w-[215px] text-[#939393] text-[12px] font-normal mt-2">
                  Trust us with your consumer data. Our robust systems ensure the security and privacy of user information, giving you and your clients peace of mind.
                  </p>
                </div>
                <div className="m-2">
                  <img
                    src={service}
                    className="w-[38px] h-[48px]"
                    alt="serviceicon"
                  ></img>
                  <p className="text-[#A6A6A6] text-[15px] font-medium mt-2">
                  24/7 Services
                  </p>
                  <p className="w-[215px] w-[215px] text-[#939393] text-[12px] font-normal mt-2">
                  Need assistance? Our dedicated support team is available around the clock to address any queries or concerns you may have.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:inline-flex md:inline-flex sm:inline-flex hidden  lg:p-[30px]  justify-center items-center w-[107px]  lg:border-l-[1px] lg:border-[#F9F9F963]">
          <div className="flex flex-col lg:ml-[30px]">
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90">
              07
            </h1>
            <div className="bg-[#627D89] h-[196px] w-[7px] rounded-lg ">
              <div className="bg-[#00B1FF] h-[56px] w-full rounded-lg "></div>
            </div>
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90s  transform rotate-90">
              02
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default  About
