import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/reset.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { updateNewPass } from "../../api/AdminRequest";
import React, { useState } from "react";
import { login } from "../imagepath";

function ResetPassword() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState("");

  const { token } = useParams();

  const handleReset = async (newPass) => {
    setLoading("loading");
    try {
      const { data } = await updateNewPass(newPass.newPass, token);
      toast.success(data.message);
      setLoading("");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.warn(error?.response?.data?.message?.message);
      } else {
        toast.warn("something went wrong , please try again ");
      }
    }
  };

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-left">
                <img className="img-fluid" src={login} alt="Logo" />
              </div>
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1 className="mb-4 text-2xl">Reset Your Password</h1>
                  <p className="my-3">Enter Your New Password</p>

                  <Form className="relative" onFinish={handleReset}>
                    <div className=" ">
                      <label>
                        Password <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="newPass"
                        rules={[
                          {
                            required: true,
                            message: "Please enter new password!",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          className="h-10 "
                          placeholder="Enter new password*"
                        />
                      </Form.Item>
                    </div>
                    <div className="">
                      <label>
                        Confirm Password <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="confirm_password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please Enter confirm password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("newPass") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The new password that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          className="h-10 "
                          placeholder="Enter confirm password*"
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group mb-0">
                      <Button
                        className="w-full h-10 rounded-md text-white bg-sky-700"
                        htmlType="submit"
                        loading={loading}
                      >
                        Reset
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default ResetPassword;
