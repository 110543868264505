import React from "react";


import "antd/dist/reset.css";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import DefaultBackground from '../../assets/img/defaultBackground.jpg'
import DefaultLogo from '../../assets/img/defaultLogo.png'

function GuestPreview({ name,logo, background1, background2, background3 }) {
 

  return (
    <>
    <div>
     
        <div className="guestView-background">
          <Carousel autoplay dots={false} className="guestView-bg">
            {background1.length > 0 ? (
              <div>
                <img
                  src={
                    background1[0]?.originFileObj

                      ? URL.createObjectURL(background1[0]?.originFileObj)
                      : background1[0]?.url
                      ? background1[0]?.url
                      : DefaultBackground
                  }
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {background2.length > 0 ? (
              <div>
                <img
                  src={
                    background2[0]?.originFileObj
                    ? URL.createObjectURL(background2[0]?.originFileObj)
                      : background2[0]?.url
                      ? background2[0]?.url
                      : DefaultBackground
                  }
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {background3.length > 0 ? (
              <div>
                <img
                  src={
                    background3[0]?.originFileObj
                    ? URL.createObjectURL(background3[0]?.originFileObj)
                      : background3[0]?.url
                      ? background3[0]?.url
                      : DefaultBackground
                  }
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {!background1?.length > 0 &&
            !background2?.length > 0 &&
            !background3?.length > 0 ? (
              <div>
                <img src={DefaultBackground} alt="" />
              </div>
            ) : (
              ""
            )}
          </Carousel>
         
          <div className="absolute top-20 w-80 h-50 ml-3">
            <div className="guestView-loginForm">
              <h1>
                <img
                  className="rounded-full"
                  src={
                    logo[0]?.originFileObj
                    ? URL.createObjectURL(logo[0]?.originFileObj)
                      : logo[0]?.url
                      ? logo[0]?.url
                      : DefaultLogo
                  }
                  alt=""
                />
              </h1>

              <div>
                <div className="guestView-card_body">
                  <form>
                    <div style={{ display: "flex" }}>
                      <div className="" style={{ width: "200px" }}>
                        <input
                          type="number"
                          className="guestView-form_control"
                          placeholder="UAE"
                          name="UAE"
                        ></input>
                      </div>
                      <div>
                        <p style={{ marginTop: "50px", marginLeft: "20px" }}>
                          |
                        </p>
                      </div>
                      <div className="">
                        <input
                          type="number"
                          className="guestView-form_control"
                          placeholder="Phone number"
                          name="number"
                        ></input>
                      </div>
                    </div>
                    <div className="">
                      <input
                        type="text"
                        className="guestView-form_control"
                        placeholder="Contact Name"
                        name="Contact_Name"
                      ></input>
                    </div>
                    <div className="input-group form-group">
                      <input
                        type="email"
                        className="guestView-form_control"
                        placeholder="Email Id"
                        name="email"
                      ></input>
                    </div>
                    <div className="guestView-Privacy">
                      <input
                        type="checkbox"
                        className="guestView-cursor_pointer"
                      />
                      <a
                        href="privacy.html
							"
                      >
                        {" "}
                        <Link to={"/termandcondition"}>Term & Condition </Link>
                      </a>
                    </div>
                    <div className="form-group mb-0">
                      <input
                        type="submit"
                        value="CONNECT TO INTERNET"
                        className="guestView-btn_primary"
                      />
                    </div>
                    <div className="mt-5" >
                     <p>
                     Powered by {name?name:"OnCloudWifi"} 
                     </p>
                       
                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    </div>
    </>
  );
}

export default GuestPreview;
