import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAdminprofile, profileUpdate } from "../../../api/AdminRequest";
import ErrorLogout from "../../../helper/ErrorLogout";
import profileImage from "../../../assets/img/profileImage.png";
import { Button, Form, Input, Upload } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImgCrop from "antd-img-crop";
import HandleImageUpload from "../../../helper/ImageCompress";
import { PlusOutlined } from "@ant-design/icons";
const Profile = ({setTrigger}) => {
    const localBucketUrl= process.env.LOCAL_IMAGE_BUCKET_URL
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [triggerHere, setTriggerHere] = useState();
  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await getAdminprofile();
        if (data.success) {
          setProfile(data?.profile);
          form.setFieldsValue({
            name: data?.profile?.name,
            email: data?.profile?.email,
            phone: data?.profile?.phone,
          });
          setFileList(
            data?.profile?.profileImage
              ? [
                  {
                    uid: "-1",
                    name: "image.png",
                    status:data?.profile?.profileImage ? "done" : "",
                    url: localBucketUrl+data?.profile?.profileImage,
                  },
                ]
              : []
          );
        }
      } catch (error) {
        console.log(error.message);
        if (error.response.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    getProfile();
  }, [triggerHere]);

  const onChange = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status) {
      newFileList[0].status = "done";
    }
    setFileList(newFileList);
  };
  const handleSubmit = async (profileData) => {
    setLoading(true)
    const LOGOImgCompress = await HandleImageUpload(fileList);
    const datas = new FormData();

    if (fileList[0]?.originFileObj && fileList[0]?.status !== "removed") {
      datas.append("profileImage", LOGOImgCompress);
    }
    if (!fileList[0]) {
      datas.append("profileImage", "delete");
    }

    for (const keys in profileData) {
      datas.append(`${keys}`, `${profileData[keys]}`);
    }
    try {
      setLoading(true);
      const { data } = await profileUpdate(datas);
      if (data?.update) {
        setLoading(false);
        setTrigger(Date.now());
        setTriggerHere(Date.now());
        toast.success("Profile updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        localStorage.setItem("user", profile?.name);
        setTimeout(() => {
          navigate("/admin/profile");
        }, 2500);
      }
    } catch (error) {
      console.log("error");
      setLoading(false);
      //   toast(error.message);
      if (error.response?.data?.message) {
        toast(error?.response?.data?.message);
      }
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row">
          <div className="col">
            <h3 className="page-title">Profile</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Profile</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}
      <div className="row">
        <div className="col-md-12">
          <div className="profile-header">
            <div className="row align-items-center">
              <div className="col-auto profile-image">
                <Link to="#">
                  <img
                    className="rounded-circle"
                    alt="User Image"
                    src={
                      fileList[0]?.originFileObj
  
                        ? URL.createObjectURL(fileList[0]?.originFileObj)
                        : fileList[0]?.url
                        ? fileList[0]?.url
                        :profile?.profileImage?localBucketUrl+profile?.profileImage
                        : profileImage
                    }
                  />
                </Link>
              </div>
              <div className="col ms-md-n2 profile-user-info">
                <h4 className="user-name mb-0">{profile?.name}</h4>
              </div>
            </div>
          </div>
          <div className="profile-menu">
            <ul className="nav nav-tabs nav-tabs-solid">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  data-bs-toggle="tab"
                  to="#per_details_tab"
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  data-bs-toggle="tab"
                  to="#password_tab"
                >
                  Edit
                </Link>
              </li>
            </ul>
          </div>
          <div className="tab-content profile-tab-cont">
            {/* Personal Details Tab */}
            <div className="tab-pane fade show active" id="per_details_tab">
              {/* Personal Details */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title d-flex justify-content-between">
                        <span>Personal Details</span>
                      </h5>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Name
                        </p>
                        <p className="col-sm-9">{profile?.name}</p>
                      </div>

                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Email ID
                        </p>
                        <p className="col-sm-9">{profile?.email}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Mobile
                        </p>
                        <p className="col-sm-9">{profile?.phone}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="password_tab" className="tab-pane fade">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <Form
                      className="relative"
                      onFinish={handleSubmit}
                      form={form}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-4">
                          <label>
                            Name <span className="login-danger">*</span>
                          </label>
                          <Form.Item
                            name="name"
                
                            rules={[
                              {
                                type: "text",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please Enter admin Name!",
                              },
                            ]}
                          >
                            <Input className="h-10" placeholder="Admin Name" />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-sm-4">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please Enter your E-mail!",
                              },
                            ]}
                          >
                            <Input
                              className="h-10"
                              placeholder="Official Email"
                              disabled={true}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-sm-4">
                          <label>
                            Phone <span className="login-danger">*</span>
                          </label>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                type: "phone",
                                message: "The input is not valid phone number!",
                              },
                              {
                                required: true,
                                message: "Please Enter admin phone number!",
                              },
                            ]}
                          >
                            <Input
                              className="h-10"
                              placeholder="Admin Phone number"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-sm-4 flex">
                        <div className="col-12 col-sm-6">
                          <label>
                            Profile Image
                          </label>
                          <ImgCrop aspect={1 / 1} aspectSlider showReset>
                            <Upload
                              action=""
                              listType="picture-card"
                              fileList={fileList}
                              onChange={onChange}
                              onPreview={onPreview}
                            >
                              {fileList[0] ? (
                            ""
                          ) :
                            uploadButton
                         }
                            </Upload>
                          </ImgCrop>
                        </div>
                        <div className="col-12 col-sm-6 mt-8">
                          <Button
                            className="w-full h-10 rounded-md text-white bg-sky-700 mt-10"
                            htmlType="submit"
                            loading={loading}
                          >
                            Save Changes
                          </Button>
                        </div>
                        </div>
                       
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Change Password Tab */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Profile;
