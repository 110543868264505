import React from 'react'
import ResetPassword from '../../components/common/RestPassword'

function RestPasswordPage() {
  return (
    <div>
        <ResetPassword/>
    </div>
  )
}

export default RestPasswordPage