import React from 'react'
import "../../assets/scss/main.scss"
import Navbar from '../../components/website/Navbar'
import Footer from '../../components/website/Footer'
import About from '../../components/website/About'
function AboutPage() {
  const frontLink='/features'
  const backLink='/'
  return (
    <div className='mainWrapper'>
       <Navbar/>
        <About />
        <Footer frontLink={frontLink} backLink={backLink} />
</div>

  )
}

export default AboutPage