import React from 'react'
import Home from '../../components/website/home'
import "../../assets/scss/main.scss"
import Navbar from '../../components/website/Navbar'
import Footer from '../../components/website/Footer'
function HomePage() {
  const frontLink='/about'
  const backLink=false
  return (
    <div className='mainWrapper'>
       <Navbar/>
        <Home />
        <Footer frontLink={frontLink} backLink={backLink}  />
</div>

  )
}

export default HomePage