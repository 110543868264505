import React, { useRef, useState } from "react";
import { login } from "../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { userLogin } from "../../api/UserRequest";
import ForgotPassword from "../common/ForgotPassword";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState("");
  const [otpView, setOTPView] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (datas) => {
    setLoginError("");
    setLoading("loading");
    try {
      const { data } = await userLogin(datas);
      if (data?.success) {
        setLoading("");
        // cookies.set('accessToken', data.accessToken, { path: '/' });
        if (data?.superAdmin) {
          localStorage.setItem("superAdmin-refToken", data.refreshToken);
          localStorage.setItem("user", data.superAdmin?.name);
          const user = data.superAdmin;
          navigate("/superAdmin/dashboard", { state: { user } });
        } else if (data?.admin) {
          localStorage.setItem("admin-refToken", data.refreshToken);
          localStorage.setItem("user", data.admin?.name);
          navigate("/admin/dashboard");
        } else {
          localStorage.setItem("user-refToken", data.refreshToken);
          localStorage.setItem("user", data.user?.name);
          navigate("/manager/dashboard");
        }
      }
    } catch (error) {
      console.log(error.response.data.message);
      setLoginError(error.response.data.message);
      setLoading("");
    }
  };

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-left">
                <img className="img-fluid" src={login} alt="Logo" />
              </div>
              <div className="login-right">
                <div className="login-right-wrap">
                  {otpView ? (
                    <ForgotPassword setOTPView={setOTPView} />
                  ) : (
                    <>
                      <h1 className="mb-4 text-2xl">Welcome to OnCloudWifi</h1>
                      <h1 className="my-3">Login</h1>

                      <Form className="relative" onFinish={handleSubmit}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not a valid email!",
                            },
                            {
                              required: true,
                              message: "Please enter your email!",
                            },
                          ]}
                        >
                          <Input
                            className="h-10"
                            placeholder="Official Email"
                          />
                        </Form.Item>

                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password!",
                            },
                          ]}
                        >
                          <Input
                            type={showPassword ? "text" : "password"}
                            className="h-10"
                            placeholder="Password*"
                          />
                        </Form.Item>
                        <span
                          className="absolute top-[75px] right-5 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <EyeOutlined
                              style={{ fontSize: "16px", color: "black" }}
                            />
                          ) : (
                            <EyeInvisibleOutlined
                              style={{ fontSize: "16px", color: "black" }}
                            />
                          )}
                        </span>

                        {loginError ? (
                          <span className="text-red-500 mx-2">
                            {loginError}
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="mb-2 flex  justify-end text-sky-400 hover:text-sky-900 hover:underline">
                         
                            <Link
                             onClick={() => { setOTPView(true) }}
                            >
                              Forgot Password?
                            </Link>
                        
                        </div>
                        <div className="form-group mb-0">
                          <Button
                            className="w-full h-10 rounded-md text-white bg-sky-700"
                            htmlType="submit"
                            loading={loading}
                          >
                            LogIn
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
