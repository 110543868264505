import React from "react";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";


import { useLocation } from "react-router-dom";
import ManagerList from "../../components/admin/service/manager/managerList";
function ManagerListPage() {
  const location = useLocation();
  const ServiceID = location.state?.serviceID;
  return (
    <div className="main-wrapper">
      <Header />

      <Sidebar />
      <div className="page-wrapper">
        <ManagerList ServiceID={ServiceID} />
        <Footer />
      </div>
    </div>
  );
}

export default ManagerListPage;
