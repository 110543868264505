import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/websiteImg/oncloudwifi_logo.gif";
import navIcon from "../../assets/websiteImg/nav_icon.svg";
import Modal from "./Modal";
 const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <nav>
        <h1 className="logo">
          <Link to="/">
            <img src={Logo} alt="oncloudwifi logo" />
          </Link>
        </h1>
        <div className="links">
          <a href="mailto:info@oncloud.com">
            <img src={navIcon} /> info@oncloud.com
          </a>
          <a href="mailto:info@oncloud.com">
            <img src={navIcon} />
            +971 4 399 1704
          </a>
          <a>
            <img src={navIcon} />
            24/7 service
          </a>
        </div>
        <button className="menu" onClick={openModal}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Navbar
