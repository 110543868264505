import React from "react";
import {Outlet,Navigate} from 'react-router-dom'

 const UserRouteProtect =()=>{
    
    let auth = {'token':localStorage.getItem('user-refToken')}
    return(
        
        auth.token ? <Outlet/> : <Navigate to={"/manager/dashboard"} />
       
    )
 }
 export default UserRouteProtect