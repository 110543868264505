import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = ({frontLink,backLink}) => {
  return (
    <>
      <div className="flex relative justify-between lg:fixed  lg:bottom-0 lg:left-0 lg:right-0 border-t-[1px] border-[#F9F9F963] ">
        <div className="absolute left-0 bottom-0 flex  items-center justify-center bg-[#07B1FC] lg:w-[107px] md:w-[107px] sm:w-[70px] w-[60px] h-[73px]">
          <div>
      {backLink? <Link to={backLink}> <div className="flex justify-center items-center"> <BsArrowLeft  color="black" size={21} /></div></Link>:''}
      {frontLink?<Link to={frontLink}>    <div className="flex justify-center items-center"> <BsArrowRight color="black" size={28} /></div></Link>:""}
        </div>
</div>
        <div className="flex p-[17px]  lg:ml-[180px] ml-[100px] lg:gap-[80px] md:gap-[80px] sm:gap-[60px] gap-[30px]">
          <div className="">
            <p className=" text-[12px] leading-4 text-[#637780] tracking-normal font-medium text-left">
              {" "}
              Version
            </p>

            <p className="text-[#FFFFFF] lg:text-[15px] md:text-[15px] sm:text-[15px] text-[8px] font-medium tracking-normal text-left leading-[22px]">
              <span className="text-[#07B1FC]">©</span> Copyright 2024. MIT.{" "}
            </p>
          </div>
          <div className="">
            <p className=" text-[12px] leading-4 text-[#637780] tracking-normal font-medium text-left">
              Policy{" "}
            </p>

            <p className="text-[#FFFFFF] lg:text-[15px] md:text-[15px] sm:text-[15px] text-[8px] font-medium tracking-normal text-left leading-[22px] lg:w-[154px] md:w-[154px] sm-[154px] w-[50px]">
              Environment/Privacy
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center absolute right-0 bottom-0 bg-[#637780] lg:w-[107px] md:w-[107px] sm:w-[70px] w-[60px] h-[73px]">
          <AiOutlinePlus color="black" size={28} />
        </div>
      </div>
    </>
  );
};

export default Footer;
