import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal, Select, Table, Tooltip,DatePicker, Button } from "antd";
import { onShowSizeChange, itemRender } from "../../Pagination";
import { AiOutlineDelete } from "react-icons/ai";
import ErrorLogout from "../../../helper/ErrorLogout";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { deleteGuest, getServiceGuest } from "../../../api/GuestRequest";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import { add, sub, isWithinInterval, startOfMonth, startOfDay, startOfWeek, startOfYear } from 'date-fns';
const { confirm } = Modal;

const { Option } = Select;
const { RangePicker } = DatePicker;

const ServiceGuestList = ({ serviceID }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [guest, setGuest] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [trigger, setTrigger] = useState();
  const [dateRange, setDateRange] = useState(null);
  const [filterType, setFilterType] = useState('all');
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const exportToExcel = (tableData, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName + ".xlsx");
  };

  useEffect(() => {
  
    const getGuest = async () => {
      try {
        const { data } = await getServiceGuest(serviceID);
        setGuest(data?.guest);
        setGuestData(data?.guest);
        setLoader(false);
      } catch (error) {
        console.log(error.message);
        if (error.response.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    getGuest();
  }, [trigger]);



  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  const handleChange = (type) => {
    setFilterType(type)
  };
  const handleFilter = async(guest,filterType,dateRange) => {
    console.log(guest,filterType,dateRange);
    const filteredData = await filterDataByDateRange(guest, filterType,dateRange);

    setGuestData(filteredData)

  };

  const filterDataByDateRange = (data, filterType,dateRange) => {
    const currentDate = new Date();
    console.log(currentDate);
    const startDate =dateRange?dateRange[0].$d:''
    const endDate =dateRange?dateRange[1].$d:''
    switch (filterType) {
      case 'today':
        return data.filter(item => isWithinInterval(item.createdDate, { start: startOfDay(currentDate), end: currentDate }));
      case 'lastWeek':
        return data.filter(item => isWithinInterval(item.createdDate, { start: startOfWeek(sub(currentDate, { weeks: 1 })), end: currentDate }));
      case 'lastMonth':
        return data.filter(item => isWithinInterval(item.createdDate, { start: startOfMonth(sub(currentDate, { months: 1 })), end: currentDate }));
      case 'last6Months':
        return data.filter(item => isWithinInterval(item.createdDate, { start: startOfMonth(sub(currentDate, { months: 6 })), end: currentDate }));
      case 'lastYear':
        return data.filter(item => isWithinInterval(item.createdDate, { start: startOfYear(sub(currentDate, { years: 1 })), end: currentDate }));
      case 'custom':
        return data.filter(item => isWithinInterval(item.createdDate, { start: startDate, end: endDate }));
      default:
        return data; // 'all' or unsupported value, return the original data
    }
  };

  const handleDelete = async (id) => {
    confirm({
      title: "Do you Want to Delete this Guest Data",
      icon: <ExclamationCircleFilled />,

      onOk() {
        const deleteCall = async () => {
          try {
            const { data } = await deleteGuest(id._id);
            if (data.delete) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        deleteCall();
      },
      okButtonProps: {
        style: {
          backgroundColor: "red",
          borderColor: "red",
          color: "white", // Text color
        },
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const column = [
    {
      title: "Sl.No",
      dataIndex: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "MacAddress",
      dataIndex: "macAddress",
    },

    {
      title: "Log In Time",
      dataIndex: "createdDate",
      render: (createdDate) => {
       
        return (
          
           <p>{moment(createdDate).format("DD-MM-YYYY hh:mm")}</p> 
         
        );
      },
    },
    {
      title: "No of Visit",
      dataIndex: "visit",
    },
    {
      title: "Action",
      dataIndex: "status._id",
      render: (status, _id) => (
        <>
          <div className="actions">
            <Tooltip title="delete Guest">
              <AiOutlineDelete size={20} onClick={() => handleDelete(_id)} />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Guest List</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/superAdmin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Guest List</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                  <div>
      <Select style={{ width: 130 }} defaultValue="all" onChange={handleChange}>
        <Option value="all">All Data</Option>
        <Option value="today">Today</Option>
        <Option value="lastWeek">Last Week</Option>
        <Option value="lastMonth">Last Month</Option>
        <Option value="last3Months">Last 3 Months</Option>
        <Option value="last6Months">Last 6 Months</Option>
        <Option value="lastYear">Last Year</Option>
        <Option value="custom">Custom Date</Option>
      </Select>
      {filterType==='custom'?
        <RangePicker
          style={{ marginLeft: 10 }}
          onChange={handleDateChange}
        />:''
      }
      <Button type="primary" onClick={()=>handleFilter(guest,filterType,dateRange)} style={{ marginLeft: 10 ,backgroundColor: '#1890ff'}}>
        Filter
      </Button>
    </div>
                  </div>
                  <div className="col-auto text-end float-end ms-auto download-grp">
                  {guestData.length==0?"":
                    <div
                      onClick={() => exportToExcel(guestData, "guest report")}
                      className="btn btn-outline-primary me-2"
                    >
                     <i className="fas fa-download" /> Download Data
                    </div>
                    }
                  </div>
                </div>
              </div>
              <Table
                class="table table-stripped table-hover datatable"
                pagination={{
                  total: guestData.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                columns={column}
                loading={loader}
                dataSource={guestData}
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceGuestList;
