import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import logo from "../../assets/img/oncloudwifi-logo.svg";
import { getService } from "../../api/GuestRequest";
import GuestLogin from "../../components/guestView/GuestLogin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import notFoundImage from '../../assets/img/404Image.avif'

function GuestViewPage() {
  const params = useParams();
  const location = useLocation();
  const clientUrl = location.pathname + location.search;
  const [service, setService] = useState("");
  const [macAddress, setmacAddress] = useState("");
  const [redirectUrl, setredirectUrl] = useState("");
  const [pre, setPre] = useState(true);
  const guestData = { actionUrl: clientUrl };
  useEffect(() => {
    const domainCall = async () => {
      try {
        const { data } = await getService(params.slug, guestData);
        if (data.IsExist) {
          toast.success("Your Our Existing Customer Thank You for Re-visit", {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setTimeout(() => {
            window.location.href = decodeURIComponent(data.redirectUrl)
          },3500);
          // console.log("data.redirectUrl",data.redirectUrl);
        }
        if (data.success) {
          setPre(false);
          setService(data.service);
          setmacAddress(data.macAddress);
          setredirectUrl(data.redirectUrl);
        }
      } catch (error) {
        setPre(false);
        setService("");
      }
    };

    domainCall();
  }, [params.slug]);
  return (
    <div>
      {pre ? (
        <div className="w-full h-screen flex items-center justify-center">
          <img src={logo} alt="" />
        </div>
      ) : service?.status === "active" ? (
        <GuestLogin service={service} macAddress={macAddress} redirectUrl={redirectUrl}/>
      ) : (
        <div className="text-center">
          <div className="w-full flex justify-center">
            <img
              className="w-2/5"
              src={notFoundImage}
              alt=""
            />
          </div>
          <Link
            to={"/"}
            className="my-2 bg-black rounded-xl text-white px-2 py-2"
          >
            Home Page
          </Link>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default GuestViewPage;
