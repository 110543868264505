import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginProtect from "./auth/LoginProtect";
import SuperAdminRouteProtect from "./auth/SuperAdminRouteProtect";
import Homepage from "./pages/webisitePages/Homepage";
import AdminTrashPage from "./pages/superAdminPages/AdminTrashListPage";
import LoginPage from "./pages/common/LoginPage";
import SuperAdminDashbordPage from "./pages/superAdminPages/SuperAdminDashbordPage";
import AddAdminPage from "./pages/superAdminPages/AddAdminPage";
import AdminListPage from "./pages/superAdminPages/AdminListPage";
import EditAdminPage from "./pages/superAdminPages/EditAdminPage";
import ProfilePage from "./pages/superAdminPages/ProfilePage";
import AdminRouteProtect from "./auth/AdminRouteProtect";
import AdminDashbordPage from "./pages/adminPages/DashboardPage";
import ServiceListPage from "./pages/adminPages/serviceListPage";
import ServiceTrashPage from "./pages/adminPages/ServiceTrashPage";
import AdminProfilePage from "./pages/adminPages/ProfilePage";
import RestPasswordPage from "./pages/common/RestPasswordPage";
import Error404Page from "./pages/common/Error404Page";
import AddServicePage from "./pages/adminPages/addServicePage";
import EditServicePage from "./pages/adminPages/editServicePage";
import EditUserPage from "./pages/adminPages/editUserPage";
import AddUserPage from "./pages/adminPages/addUserPage";
import UserListPage from "./pages/adminPages/userListPage";
import GuestListPage from "./pages/userPages/guestListPage";
import UserRouteProtect from "./auth/UserRouteProtect";
import ServiceTypeListPage from "./pages/superAdminPages/ServiceTypeListPage";
import ManagerListPage from "./pages/adminPages/managerPage";
import ServiceGuestPage from "./pages/adminPages/serviceGuestPage";
import GuestViewPage from "./pages/guest/guestView";
import ManagerDashbordPage from "./pages/userPages/DashboardPage";
import ManagerProfilePage from "./pages/userPages/ProfilePage";
import AboutPage from "./pages/webisitePages/AboutPage";
import FeaturesPage from "./pages/webisitePages/FeaturesPage";
import PricingPage from "./pages/webisitePages/PricingPage";
import ClientPage from "./pages/webisitePages/ClientsPage";
import WorksPage from "./pages/webisitePages/WorksPage";
import ContactPage from "./pages/webisitePages/ContactPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Error404Page />} />
        <Route path="/changePassword/:token" element={<RestPasswordPage />} />
        <Route path="/:slug" element={<GuestViewPage />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/clients" element={<ClientPage />} />
        <Route path="/works" element={<WorksPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route element={<LoginProtect />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route element={<SuperAdminRouteProtect />}>
          <Route
            path="/superAdmin/dashboard"
            element={<SuperAdminDashbordPage />}
          />
          <Route
            path="/superAdmin/serviceType"
            element={<ServiceTypeListPage />}
          />
          <Route path="/superAdmin/adminList" element={<AdminListPage />} />
          <Route path="/superAdmin/addAdmin" element={<AddAdminPage />} />
          <Route path="/superAdmin/editAdmin" element={<EditAdminPage />} />
          <Route path="/superAdmin/adminTrash" element={<AdminTrashPage />} />
          <Route path="/superAdmin/profile" element={<ProfilePage />} />
        </Route>
        <Route element={<AdminRouteProtect />}>
          <Route path="/admin/dashboard" element={<AdminDashbordPage />} />
          <Route path="/admin/serviceList" element={<ServiceListPage />} />
          <Route path="/admin/serviceTrash" element={<ServiceTrashPage />} />
          <Route path="/admin/profile" element={<AdminProfilePage />} />
          <Route path="/admin/addService" element={<AddServicePage />} />
          <Route path="/admin/editService" element={<EditServicePage />} />
          <Route path="/admin/managerList" element={<ManagerListPage />} />
          <Route path="/admin/serviceGuest" element={<ServiceGuestPage />} />
          <Route path="/admin/addUser" element={<AddUserPage />} />
          <Route path="/admin/editUser" element={<EditUserPage />} />
          <Route path="/admin/userList" element={<UserListPage />} />
        </Route>
        <Route element={<UserRouteProtect />}>
          <Route path="/manager/dashboard" element={<ManagerDashbordPage />} />
          <Route path="/manager/profile" element={<ManagerProfilePage />} />
          <Route path="/manager/guestList" element={<GuestListPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
