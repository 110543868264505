import { Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
const { Text } = Typography;
function TopServiceList({ serviceProp }) {
    const data=
        serviceProp.map((item, index) => (
            expiration = moment(item.expireDate).format("YYYY-MM-DD"),
           current_date = moment().format("YYYY-MM-DD"),
           days = moment(expiration).diff(current_date, "days"),
         <tr key={index}>
           <td className="text-nowrap">
             <div>{index + 1}</div>
           </td>
           {/* <td className="text-nowrap">{item.serviceName}</td> */}
           <td className="text-left">{item.name}</td>
           <td className="text-left">{item.email}</td>
           <td className="text-left">{item.phone}</td>
           <td className={`${days > 6 ? "text-success" : days > 3 ? "text-warning" : "text-danger"}`}> {moment(item.expireDate).format("DD-MM-YYYY")}</td>
           <td className={`${days > 6 ? "text-success" : days > 3 ? "text-warning" : "text-danger"}`}> {days}</td>
     
         </tr>
       ))
    

  return (
    <div className="row">
      <div className="col-xl-12 d-flex">
        {/* Star Students */}
        <div className="card flex-fill student-space comman-shadow">
          <div className="card-header d-flex align-items-center">
            <h5 className="card-title">Admin Expiry Notification: Time to Renew</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table star-student table-hover table-center table-borderless table-striped">
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    {/* <th>Service Name</th> */}
                    <th className="text-left">Admin Name</th>
                    <th className="text-left">Admin Email</th>
                    <th className="text-left">Admin Phone</th>
                    <th className="text-left">Expire Date</th>
                    <th className="text-left">Days Left</th>
                  </tr>
                </thead>

                <tbody>
                {serviceProp.map((item, index) => {
  const expiration = moment(item.expireDate).format("YYYY-MM-DD");
  const current_date = moment().format("YYYY-MM-DD");
  const days = moment(expiration).diff(current_date, "days");

  return (
    <tr key={index}>
      <td className="text-nowrap">
        <div>{index + 1}</div>
      </td>
      <td className="text-left">{item.name}</td>
      <td className="text-left">{item.email}</td>
      <td className="text-left">{item.phone}</td>
      <td className={`${days > 6 ? "text-success" : days > 3 ? "text-warning" : "text-danger"}`}>
        {moment(item.expireDate).format("DD-MM-YYYY")}
      </td>
      <td className={`${days > 6 ? "text-success" : days > 3 ? "text-warning" : "text-danger"}`}>
        {days}
      </td>
    </tr>
  );
})}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopServiceList;
