import React from "react";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import ServiceGuestList from "../../components/admin/service/GuestList";
function serviceGuestPage() {
  const location = useLocation();
  const serviceID = location.state?.serviceID;
  return (
    <div className="main-wrapper">
      <Header />

      <Sidebar />
      <div className="page-wrapper">
        <ServiceGuestList serviceID={serviceID} />
        
        <Footer />
      </div>
    </div>
  );
}

export default serviceGuestPage;
