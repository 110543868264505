import React from "react";
import Dashboard from "../../components/admin/dashboard/Dashboard";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";

function AdminDashbordPage() {
  return (
    <div className="main-wrapper">
      <Header sprAdmin={true} />

      <Sidebar />
      <div className="page-wrapper">
        <Dashboard />
        <Footer />
      </div>
    </div>
  );
}

export default AdminDashbordPage;
