import React, {useState ,useRef} from "react";
import { PiHeadsetBold } from "react-icons/pi";
import { PiTrafficSignBold } from "react-icons/pi";
import Settingicon from "../../assets/websiteImg/setting_icon.svg";
import Licationimg from "../../assets/websiteImg/locationimage.png";
import { Button } from "antd";
import GoogleMap from "./GooleMap";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";

import emailjs from "@emailjs/browser";
 const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    service: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  
    // Clear validation error when the user types
    setErrors({
      ...errors,
      [id]: undefined,
    });
  };
  

  const showToast = (message, type = 'success') => {
    toast[type](message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation checks
    const validationErrors = {};
    const requiredFields = ['name', 'email', 'service', 'message'];

    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        validationErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (formData.email.trim() && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = 'Invalid email format';
    }

    // Update errors state with validation errors
    setErrors(validationErrors);

    // If no validation errors, submit the form
    if (Object.keys(validationErrors).length === 0) {
      // Perform form submission logic here (e.g., send data to server)
      // console.log("Form submitted:", formData);
      console.log("we reached here", formData);
 
      emailjs.sendForm("service_snitjm8", "template_cqcsadf", formData.current, "p87NmtLp7L4bobmxM")


        .then((response) => {
          console.log("Email sent successfully:", formData);
          showToast("Email sent successfully", "success");
        })

        .catch((error) => {
          console.error("Error sending email:", error);
          showToast("Error sending email", "error");
        });
    }
  };

  return (
    
    <>
      <div className="flex">
      <div className="sidenav w-[107px] lg:p-[30px]  flex flex-col  text-[13px] lg:border-r-[1px] lg:border-[#F9F9F963] uppercase font-normal tracking-normal text-left text-[#627D89] lg:items-center lg:gap-[80px] sm:gap-[5] lg:justify-center sm:justify-evenly justify-around  transform ">
          <a href="#" className=" transform -rotate-90">
            Whatsapp
          </a>
          <a href="#" className=" transform -rotate-90">
            Instagram
          </a>
          <a href="#" className=" transform -rotate-90">
            linkedin
          </a>
          <a href="#" className=" transform -rotate-90">
            Facebook
          </a>
        </div>
        <div className="container mx-auto mt-[10px]">
          <div className=" lg:flex lg:justify-center lg:ml-[20px] lg:mr-[20px] gap-[70px] ">
            <div className="mt-[10px] ">
              <button className="text-white bg-[#D9D9D92E]  w-[146px] h-[42px] rounded-[45px] ">
                <div className="flex p-1 justify-center gap-2 text-[#62767F] font-[17px]">
                  <PiHeadsetBold className="mt-[3px] text-[#62767F]" />
                  Contact Now
                </div>
              </button>
              <div>
                <p className="text-[#ABABAB] lg:leading-[53px] lg:text-[50px] sm:text-[40px] text-[40px] mt-[5px] font-semibold lg:w-[499px] md:w-[499px] sm:w-[499px]  w-[329px]">
                  Contact us today and{" "}
                  <span className="text-[#00B1FF]">
                    let’s talk about your requirement
                  </span>
                </p>
              </div>
              <div className="lg:flex gap-5 mt-[20px]">
                <div className="">
                  <div className="flex gap-3 ">
                    <img src={Settingicon} alt="Settingicon"></img>
                    <p className="text-[15px] font-normal tracking-normal text-left text-[#627D89]">
                      Phone
                    </p>
                  </div>
                  <p className="text-[15px] font-normal tracking-normal text-left text-[#627D89] mt-[10px]">
                  +971 4 399 1704
                  </p>
                  <div className="flex gap-3 mt-[20px] ">
                    <img src={Settingicon} alt="Settingicon"></img>
                    <p className="text-[15px] font-normal tracking-normal text-left text-[#627D89]">
                      Address
                    </p>
                  </div>
                  <p className="w-[216px] h-[87px] font-normal tracking-normal text-left text-[#627D89] mt-[10px]">
                  Office No. 216A, PO Box No. 90071, Ittihad Square, Al Ittihad
                Road , Dubai, UAE
                  </p>
                </div>
                <div className="relative">

                <GoogleMap />
                {/* <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.925923350309!2d55.34534707437951!3d25.27307722862501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d8fcde5080d%3A0xa103a2b269d59102!2sMetasoft%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1703828288998!5m2!1sen!2sin">
      <Button className="absolute cursor-pointer pointer-events-none left-[30px] bottom-[20px] flex justify-between items-center w-[135px] h-[40px] m-auto border-none rounded-full text-[#051F2A] text-[11px] font-semibold text-left bg-[#00B1FF]">
        Get the Direction{" "}
        <PiTrafficSignBold className="text-[12px]" />
      </Button>
    </Link> */}
                </div>
                {/* <div className="">
                  <div className="relative">
                    <img
                      src={Licationimg}
                      alt="Your Image"
                      className="lg:h-[180px] lg:w-[190px]"
                    ></img>
                    <Button className="absolute pointer-events-none lg:top-[130px] lg:left-[30px] top-[180px] left-[50px] flex  w-[129px] h-[33px] gap-2 p-2   border-none rounded-full text-[#051F2A] text-[11px] font-semibold  text-left bg-[#00B1FF]">
                      Get the Direction{" "}
                      <PiTrafficSignBold className=" text-[12px] lg:mt-[1px]" />
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="lg:mt-[30px] md:mt-[20px] sm:mt-[0px] mt-[40px] mb-[12px] ">
              <form className="space-y-4" ref={formData} onSubmit={handleSubmit}>
              <ToastContainer />
                <div className="">
                  <div className="flex gap-3">
                    <p className="text-[#051E29] bg-[#00B1FF] w-[50px] text-center rounded-full h-[29px] text-[18px] text-left font-semibold">
                      01
                    </p>
                    <label className="block text-[#ABABAB] text-[16px] text-left font-medium ">
                      What’s Your Name?
                    </label>
                  </div>
                  <input
                  id="name"
                    type="text"
                    name="name"
                    className=" mt-[4px] font-medium w-[280px] lg:w-[500px]  text-[#627D89] text-[13px] text-left  py-2 bg-transparent  border-b-2 border-[#D9D9D961] focus:outline-none focus:border-blue-500"
                    placeholder="Type your full name"
                    value={formData.name}
                    onChange={handleInputChange}
                  >
                    
                  </input>
                  <div className="text-start">
                      {errors.name && (
                        <span className="text-red-500">{errors.name}</span>
                      )}
                    </div>
                     
                </div>
                <div className="">
                  <div className="flex mt-[10px] gap-3">
                    <p className="text-[#051E29] bg-[#00B1FF] w-[50px] text-center rounded-full h-[29px] text-[18px] text-left font-semibold">
                      02
                    </p>
                    <label className="block text-[#ABABAB] text-[16px] text-left font-medium ">
                      What’s Your Email Address?
                    </label>
                  </div>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className=" mt-[4px] font-medium w-[280px] lg:w-[500px] text-[#627D89] text-[13px] text-left  py-2 bg-transparent  border-b-2 border-[#D9D9D961] focus:outline-none focus:border-blue-500"
                    placeholder="example@gmail.com"
                    value={formData.email}
                    onChange={handleInputChange}
                  ></input>
                        <div className="text-start">
                      {errors.email && (
                        <span className="text-red-500">{errors.email}</span>
                      )}
                    </div>
                </div>
                <div>
                  <div className="flex mt-[10px] gap-3">
                    <p className="text-[#051E29] bg-[#00B1FF] w-[50px] text-center rounded-full h-[29px] text-[18px] text-left font-semibold">
                      03
                    </p>
                    <label className="block text-[#ABABAB] text-[16px] text-left font-medium ">
                      Which Service Are You Looking For?
                    </label>
                  </div>
                  <input
                    type="text"
                    id="service"
                    name="service"
                    className="lg:w-[500px] w-[280px] mt-[4px] font-medium text-[#627D89] text-[13px] text-left  py-2 bg-transparent  border-b-2 border-[#D9D9D961] focus:outline-none focus:border-blue-500"
                    placeholder="OnCloudWifi Marketing"
                    value={formData.service}
                    onChange={handleInputChange}
                  ></input>
                        <div className="text-start">
                      {errors.service && (
                        <span className="text-red-500">{errors.service}</span>
                      )}
                    </div>
                </div>
                <div>
                  <div className="flex mt-[10px] gap-3">
                    <p className="text-[#051E29] bg-[#00B1FF] w-[50px] text-center rounded-full h-[29px] text-[18px] text-left font-semibold">
                      04
                    </p>
                    <label className="block text-[#ABABAB] text-[16px] text-left font-medium ">
                      Your Message
                    </label>
                  </div>
                  <input
                    type="text"
                    id="message"
                    name="message"
                    className="lg:w-[500px] w-[280px] mt-[4px] font-medium text-[#627D89] text-[13px] text-left  py-2 bg-transparent  border-b-2 border-[#D9D9D961] focus:outline-none focus:border-blue-500"
                    placeholder="Send us your idea and requirement "
                    value={formData.message}
                    onChange={handleInputChange}
                  ></input>
                        <div className="text-start">
                      {errors.message && (
                        <span className="text-red-500">{errors.message}</span>
                      )}
                    </div>
                </div>
                <button type="submit" className="px-4 curser-pointer py-2 bg-[#163F51] text-[#00B1FF] font-bold text-[16px] border-none rounded-full w-[185px] h-[40px]">
    Submit
  </button>
              </form>
            </div>
          </div>
        </div>
        <div className="flex lg:inline-flex md:inline-flex sm:inline-flex hidden  lg:p-[30px]   justify-center items-center w-[116px] lg:border-l-[1px]  lg:border-[#F9F9F963]">
          <div className="flex flex-col lg:ml-[20px]">
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90">
              07
            </h1>
            <div className="bg-[#627D89] h-[196px] w-[7px] rounded-lg ">
              <div className="bg-[#00B1FF] h-[196px] w-full rounded-lg "></div>
            </div>
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90s  transform rotate-90">
              07
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact
