import React from 'react'
import "../../assets/scss/main.scss"
import Navbar from '../../components/website/Navbar'
import Footer from '../../components/website/Footer'
import Pricing from '../../components/website/Pricing'
function PricingPage() {
  const frontLink='/clients'
  const backLink='/features'
  return (
    <div className='mainWrapper'>
       <Navbar/>
        <Pricing />
        <Footer frontLink={frontLink} backLink={backLink} />
</div>

  )
}

export default PricingPage