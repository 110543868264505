import React from "react";
import {Outlet,Navigate} from 'react-router-dom'

 const AdminRouteProtect =()=>{
    
    let auth = {'token':localStorage.getItem('admin-refToken')}
    return(
        
        auth.token ? <Outlet/> : <Navigate to={"/login"} />
       
    )
 }
 export default AdminRouteProtect