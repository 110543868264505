import React from "react";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import AddService from "../../components/admin/service/AddService";

function AddServicePage() {
  return (
    <div className="main-wrapper">
    <Header/>

    <Sidebar />
    <div className="page-wrapper">
      <AddService />
      <Footer />
    </div>
  </div>
  )
}

export default AddServicePage