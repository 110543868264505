import React from "react";
import { Card } from "antd";

import { MdOutlineRefresh } from "react-icons/md";
import Cardimg1 from "../../assets/websiteImg/cardimg1.png";
import Cardimg2 from "../../assets/websiteImg/cardimg2.png";
import Cardimg3 from "../../assets/websiteImg/cardimg3.png";
import Cardimg4 from "../../assets/websiteImg/cardimg4.png";
import Cardimg5 from "../../assets/websiteImg/Cardimg5.svg";
const Features = () => {
  return (
    <>
      <div className="flex">
        <div className="sidenav w-[107px] lg:p-[30px] flex flex-col    text-[13px] lg:border-r-[1px] lg:border-[#F9F9F963] uppercase font-normal tracking-normal text-left text-[#627D89] lg:items-center lg:gap-[80px] sm:gap-[5] lg:justify-center sm:justify-evenly justify-around  transform ">
          <a href="#" className=" transform -rotate-90">
            Whatsapp
          </a>
          <a href="#" className=" transform -rotate-90">
            Instagram
          </a>
          <a href="#" className=" transform -rotate-90">
            linkedin
          </a>
          <a href="#" className=" transform -rotate-90">
            Facebook
          </a>
        </div>
        <div className="md:container md:mx-auto flex justify-center">
          <div>
            <div className="mt-[30px] ">
              <button className="text-white bg-[#D9D9D92E]  w-[146px] h-[42px] rounded-[45px] ">
                <div className="flex p-1 justify-center gap-2 text-[#62767F] font-[17px]">
                  <MdOutlineRefresh className="mt-[3px] text-[#62767F]" />
                  Features
                </div>
              </button>
            </div>
            <div>
              <p className="text-[#00B1FF] text-[50px] leading-[55px] mt-[15px] font-semibold md:w-[672px] ">
                Why you need{" "}
                <span className="text-[#ABABAB]">
                  to connect with OnCloudWifi
                </span>
              </p>
            </div>

            <div className="flex flex-wrap justify-center gap-3 mt-[30px] ">
              <div className="">
                <Card className=" w-[250px] mb-[20px]  h-[250px] shrink-0  rounded-[13px] backdrop-blur border-none bg-[#D9D9D936]">
                  <div className="">
                    <div className="flex gap-3">
                      <img
                        src={Cardimg1}
                        className="w-[35px] h-[35px]"
                        alt="cardimg1"
                      ></img>
                      <div>
                        {/* <p className="text-[#637780] text-[12px] font-medium uppercase">
                          Version
                        </p> */}

                        <p className="text-[#EEE] text-[13px]  font-medium">
                        Seamless Connectivity Experience
                        </p>
                      </div>
                    </div>

                    <p className="w-[220px] h-[100px] shrink-0 text-[11px] font-normal mt-[20px] text-[#A7A7A7]">
                    At On Cloud WiFi, we prioritize seamless connectivity. Our intuitive WiFi logins ensure effortless internet access, enhancing satisfaction and encouraging repeat visits. Goodbye to complexity, hello to user-friendly connections.
                    </p>

                   
                  </div>
                  <div className="h-[25px] w-[215px] bottom-[0px] top-[235px] left-5 absolute rounded-lg opacity-50  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </Card>
              </div>

              <div className="">
                <Card className=" w-[250px] mb-[20px]  h-[250px] shrink-0  rounded-[13px] backdrop-blur border-none bg-[#D9D9D936]">
                  <div className="">
                    <div className="flex gap-3">
                      <img
                        src={Cardimg2}
                        className="w-[35px] h-[35px]"
                        alt="cardimg1"
                      ></img>
                      <div>
                       

                        <p className="text-[#EEE] text-[13px] font-medium">
                        Robust Data Security Measures
                        </p>
                      </div>
                    </div>

                    <p className="w-[220px] h-[100px] shrink-0 text-[11px] font-normal mt-[20px] text-[#A7A7A7]">
                    Security is paramount at On Cloud WiFi. We employ advanced encryption and security measures, ensuring robust protection for your business network and consumer data. Trust us to fortify your network against cyber threats, offering peace of mind for you and your customers.
                    </p>

                 
                  </div>
                  <div className="h-[25px] w-[215px] bottom-[0px] top-[235px] left-5 absolute rounded-lg opacity-50  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </Card>
              </div>
              <div className="">
                <Card className=" w-[250px]  mb-[20px] h-[250px] shrink-0  rounded-[13px] backdrop-blur border-none bg-[#D9D9D936]">
                  <div className="">
                    <div className="flex gap-3">
                      <img
                        src={Cardimg3}
                        className="w-[35px] h-[35px]"
                        alt="cardimg1"
                      ></img>
                      <div>
    <p className="text-[#EEE] text-[13px] font-medium">
                        Efficient Consumer Data Management
                        </p>
                      </div>
                    </div>
                    <p className="w-[220px] h-[100px] shrink-0 text-[11px] font-normal mt-[20px] text-[#A7A7A7]">
                    Your customers are invaluable sources of insights. On Cloud WiFi goes beyond connectivity, providing efficient consumer data management. Gain insights into behavior, preferences, and demographics, empowering tailored services and effective marketing. Our solutions support informed business decisions.
                    </p>
                  </div>

                  <div className="h-[25px] w-[215px] bottom-[0px] top-[235px] left-5 absolute rounded-lg opacity-50  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </Card>
              </div>

              <div className="">
                <Card className="w-[250px] mb-[20px]  h-[250px] shrink-0  rounded-[13px] backdrop-blur border-none bg-[#D9D9D936]">
                  <div className="">
                    <div className="flex gap-3">
                      <img
                        src={Cardimg4}
                        className="w-[35px] h-[35px]"
                        alt="cardimg1"
                      ></img>
                      <div>
                   

                        <p className="text-[#EEE] text-[13px] font-medium">
                        24/7 Dedicated Support
                        </p>
                      </div>
                    </div>

                    <p className="w-[220px] h-[100px] shrink-0 text-[11px] font-normal mt-[20px] text-[#A7A7A7]">
                    In the dynamic tech world, support is crucial. On Cloud WiFi excels with 24/7 commitment. Questions or issues? Our dedicated team ensures prompt assistance. With us, you're not just a customer; you're a valued partner in the seamless connectivity journey.
                    </p>

                    {/* <div className="flex gap-3 mt-[10px]">
                      <p className="text-[#EEE] text-[12px]  font-medium uppercase">
                        Contact Now
                      </p>
                      <img src={Cardimg5} alt="cardimg5"></img>
                    </div> */}
                  </div>
                  <div className="h-[25px] w-[215px] bottom-[0px] top-[235px] left-5 absolute rounded-lg opacity-50  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:inline-flex md:inline-flex sm:inline-flex hidden lg:p-[32px]  w-[107px] justify-center items-center  lg:border-l-[1px] lg:border-[#F9F9F963]">
          <div className="flex flex-col lg:ml-[20px]">
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90">
              07
            </h1>
            <div className="bg-[#627D89] h-[196px] w-[7px] rounded-lg ">
              <div className="bg-[#00B1FF] h-[84px] w-full rounded-lg "></div>
            </div>
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90s  transform rotate-90">
              03
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features