import React from "react";
import { LiaToolsSolid } from "react-icons/lia";
import Worklogo from "../../assets/websiteImg/worklogo.png";
import Worklogo1 from "../../assets/websiteImg/worklogo1.png";
import Workpageimg from "../../assets/websiteImg/workpageimg.png";
 const Works = () => {
  return (
    <div className="flex">
      <div className="sidenav w-[107px] flex flex-col lg:p-[30px]    text-[13px] lg:border-r-[1px] lg:border-[#F9F9F963] uppercase font-normal tracking-normal text-left text-[#627D89] lg:items-center lg:gap-[80px] sm:gap-[5] lg:justify-center sm:justify-evenly justify-around  transform ">
        <a href="#" className=" transform -rotate-90">
          Whatsapp
        </a>
        <a href="#" className=" transform -rotate-90">
          Instagram
        </a>
        <a href="#" className=" transform -rotate-90">
          linkedin
        </a>
        <a href="#" className=" transform -rotate-90">
          Facebook
        </a>
      </div>
      <div className="md:container md:mx-auto mt-[20px]">
        <div className="lg:flex gap-[80px]  ml-[20px] mr-[20px]">
          <div>
            <img
              src={Workpageimg}
              className="lg:h-[400px]"
              alt="workpageimg"
            ></img>
          </div>
          <div>
            <div className="lg:mt-[10px] md:mt-[10px] sm:mt-[10px] mt-[30px] ">
              <button className="text-white bg-[#D9D9D92E]  w-[146px] h-[42px] rounded-[45px] ">
                <div className="flex p-1 justify-center gap-2 text-[#62767F] font-[17px]">
                  <LiaToolsSolid className="mt-[3px] text-[#62767F]" />
                  How Works
                </div>
              </button>
            </div>
            <div>
              <p className="text-[#ABABAB] text-[50px] mt-[1px] font-semibold md:w-[579px] ">
                Use Of {""}
                <span className="text-[#00B1FF]">Wifi Marketing</span>
              </p>
              <p className="lg:w-[578px] lg:h-[55px] shrink-0 text-[17px] font-normal mt-[1px] text-[#637780]">
              Elevate business outreach with strategic WiFi marketing, connecting, engaging, and growing customer relationships seamlessly{" "}
              </p>
            </div>
            <div className="lg:flex gap-[30px]  mt-[5px]">
              <div className="flex gap-3 p-[10px] ">
                <img
                  src={Worklogo}
                  className="bg-[#00B1FF] p-[10px] rounded-[10px] shrink-0 w-[59px] h-[59px]"
                  alt="worklogo1"
                ></img>
                <p className="text-[#ABABAB] md:w-[108px] h-[43px] text-[14px] font-medium">
                  Number of visitors
                </p>
              </div>
              <div className="flex gap-3 p-[10px] ">
                <img
                  src={Worklogo1}
                  className="bg-[#00B1FF] p-[10px] rounded-[10px] shrink-0 w-[59px] h-[59px]"
                  alt="worklogo1"
                ></img>
                <p className="text-[#ABABAB] md:w-[155px] h-[43px] text-[14px] font-medium">
                  Marketing/ Email Marketing
                </p>
              </div>
              <div className="flex gap-3 p-[10px] ">
                <img
                  src={Worklogo}
                  className="bg-[#00B1FF] p-[10px] rounded-[10px] shrink-0 w-[59px] h-[59px]"
                  alt="worklogo1"
                ></img>
                <p className="text-[#ABABAB] md:w-[125px] h-[43px] text-[14px] font-medium">
                  Promotions/ Loyalty
                </p>
              </div>
            </div>
            <div className="mt-[10px] ">
              <div className="pb-[20px]">
                <div className="flex justify-between mb-1">
                  <span className=" font-medium text-[#627D89] text-[14px]">
                    Working process
                  </span>
                  <span className="font-medium text-[#627D89] text-[14px]">
                    80%
                  </span>
                </div>
                <div className="w-full bg-[#627D89] rounded-full h-[6px] dark:bg-gray-700">
                  <div className="bg-[#00B1FF] h-[6px] rounded-full w-[80%]"></div>
                </div>
              </div>
              <div className="pb-[20px]">
                <div className="flex justify-between mb-1">
                  <span className=" font-medium text-[#627D89] text-[14px]">
                    Speed of Login
                  </span>
                  <span className="font-medium text-[#627D89] text-[14px]">
                    65%
                  </span>
                </div>
                <div className="w-full bg-[#627D89] rounded-full h-[6px] dark:bg-gray-700">
                  <div className="bg-[#00B1FF] h-[6px] rounded-full w-[65%]"></div>
                </div>
              </div>
              <div className="pb-[29px]">
                <div className="flex justify-between mb-1">
                  <span className=" font-medium text-[#627D89] text-[14px]">
                    User Friendly
                  </span>
                  <span className="font-medium text-[#627D89] text-[14px]">
                    95%
                  </span>
                </div>
                <div className="w-full bg-[#627D89] rounded-full h-[6px] dark:bg-gray-700">
                  <div className="bg-[#00B1FF] h-[6px] rounded-full w-[95%]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex lg:inline-flex md:inline-flex sm:inline-flex hidden    sm:mr-[0px] justify-center items-center w-[115px] lg:p-[30px]  lg:border-l-[1px] lg:border-[#F9F9F963]">
        <div className="flex flex-col lg:ml-[20px]">
          <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90">
            07
          </h1>
          <div className="bg-[#627D89] h-[196px] w-[7px] rounded-lg ">
            <div className="bg-[#00B1FF] h-[168px] w-full rounded-lg "></div>
          </div>
          <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90s  transform rotate-90">
            06
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Works
