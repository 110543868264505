import React from 'react'
import "../../assets/scss/main.scss"
import Navbar from '../../components/website/Navbar'
import Footer from '../../components/website/Footer'
import Works from '../../components/website/Works'
function WorksPage() {
  const frontLink='/contact'
  const backLink='/clients'
  return (
    <div className='mainWrapper'>
       <Navbar/>
        <Works />
        <Footer frontLink={frontLink} backLink={backLink} />
</div>

  )
}

export default WorksPage