import axios from '../api/Axios'
// import UseAxiosPrivate from '../hooks/UseAxiosPrivate'

import { axiosPrivate } from './AxiosPrivate'

export const userSignup = (signupData) => axios.post('/signup',signupData)

export const userLogin = (loginData) => axios.post('/login',loginData)


