import React from 'react'
import "../../assets/scss/main.scss"
import Navbar from '../../components/website/Navbar'
import Footer from '../../components/website/Footer'
import Client from '../../components/website/Client'
function ClientPage() {
  const frontLink='/works'
  const backLink='/pricing'
  return (
    <div className='mainWrapper'>
       <Navbar/>
        <Client />
        <Footer frontLink={frontLink} backLink={backLink} />
</div>

  )
}

export default ClientPage