
import { axiosPrivate } from './AxiosPrivate'


export const guestLogin = (guestData) => axiosPrivate.post('/create-guest',guestData)

export const getAllGuest = () => axiosPrivate.get(`/view-allguest`)

export const getUserGuest = () => axiosPrivate.get('/user-view-guest')

export const getServiceGuest = (serviceID) => axiosPrivate.get(`/service-view-guest/${serviceID}`)

export const getService = (serviceSlug,clientUrl) => axiosPrivate.post(`/get-Service/${serviceSlug}`,clientUrl)

export const deleteGuest = (serviceId) =>  axiosPrivate.put(`/delete-guest/${serviceId}`)

















