import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const LoginProtect = () => {
  let auth = { token: localStorage.getItem("superAdmin-refToken") };
  if (auth.token) {
    return <Navigate to={"/superAdmin/dashboard"} />;
  }
  let adminAuth = { token: localStorage.getItem("admin-refToken") };

  if (adminAuth.token) {
    return <Navigate to={"/admin/dashboard"} />;
  } else {
    let auth = { token: localStorage.getItem("user-refToken") };
    return !auth.token ? <Outlet /> : <Navigate to={"/manager/dashboard"} />;
  }
};

export default LoginProtect;
