import React from "react";
import { BsTags } from "react-icons/bs";
import Slider from "react-slick";
import Clientsliderimg1 from "../../assets/websiteImg/clientsliderimg1.png";
import Clientsliderimg2 from "../../assets/websiteImg/clientsliderimg2.png";
import Clientsliderimg3 from "../../assets/websiteImg/clientsliderimg3.png";
import Clientsliderimg4 from "../../assets/websiteImg/clientsliderimg4.png";
import Clientsliderimg5 from "../../assets/websiteImg/clientsliderimg5.png";
import Clientsliderimg6 from "../../assets/websiteImg/clientsliderimg6.png";

 const Client = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 5,
  };
  return (
    <>
      <div className="flex">
      <div className="sidenav w-[347px] flex flex-col   text-[13px] lg:border-r-[1px] lg:border-[#F9F9F963] uppercase font-normal tracking-normal text-left text-[#627D89] lg:items-center lg:gap-[80px] sm:gap-[5] lg:justify-center sm:justify-evenly justify-around  transform ">
          <a href="#" className=" transform -rotate-90">
            Whatsapp
          </a>
          <a href="#" className=" transform -rotate-90">
            Instagram
          </a>
          <a href="#" className=" transform -rotate-90">
            linkedin
          </a>
          <a href="#" className=" transform -rotate-90">
            Facebook
          </a>
        </div>
        <div className="lg:min-w-[320px] min-w-[150px]  m-5  sm:m-0 mt-[0px]">
          <div className="">
            <div className="lg:flex lg:justify-center">
              <div className="mt-[1px] ">
                <button className="text-white bg-[#D9D9D92E]  w-[146px] h-[42px] rounded-[45px] ">
                  <div className="flex p-1 justify-center gap-2 text-[#62767F] font-[17px]">
                    <BsTags className="mt-[3px] text-[#62767F]" />
                    Clients
                  </div>
                </button>

                <div>
                  <p className="text-[#ABABAB] text-[45px] mt-[10px] font-semibold leading-[52px] md:w-[602px]">
                    Past <span className="text-[#00B1FF]">4 Year</span> of
                    Excellence Service Provider...
                  </p>

                  <p className="text-[#637780] md:w-[458px] mt-1 lg:h-[40px] text-[14px] font-normal">
                  Proudly delivering exceptional service for the past four years, setting benchmarks as a trusted provider.{" "}
                  </p>
                </div>
              </div>
              <div className="mt-[30px]">
                <div className="lg:flex md:flex sm:flex md:text-left lg:text-left sm:text-left text-center gap-[15px] ">
                  <div className="lg:border-r-2 lg:border-[#D9D9D987] md:border-r-2 md:border-[#D9D9D987] sm:border-r-2 sm:border-[#D9D9D987]  pr-[20px] pl-[10px] ">
                    <h2 className="text-[#A6A6A6] text-[40px] font-bold">
                      1k<span className="text-[#00B1FF] font-normal">+</span>
                    </h2>
                    <p className="font-normal text-[13px] text-[#A6A6A6]">
                      Clients
                    </p>
                  </div>
                  <div className="lg:border-r-2 lg:border-[#D9D9D987] md:border-r-2 md:border-[#D9D9D987] sm:border-r-2 sm:border-[#D9D9D987]  pr-[20px] pl-[10px]">
                    <h2 className="text-[#A6A6A6] text-[40px] font-bold">
                      100k<span className="text-[#00B1FF] font-normal">+</span>
                    </h2>
                    <p className="font-normal text-[13px] text-[#A6A6A6]">
                    number of users
                    </p>
                  </div>
                  <div className="lg:border-r-2 lg:border-[#D9D9D987] md:border-r-2 md:border-[#D9D9D987] sm:border-r-2 sm:border-[#D9D9D987]  pr-[20px] pl-[10px] ">
                    <h2 className="text-[#A6A6A6] text-[40px] font-bold">
                      10k<span className="text-[#00B1FF] font-normal">+</span>
                    </h2>
                    <p className="font-normal text-[13px] text-[#A6A6A6]">
                    number of Subscribe
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-[25px] lg:ml-[30px] lg:mr-[30px] md:ml-[30px] md:mr-[30px] sm:ml-[30px] sm:mr-[30px] ml-[0px] mr-[0px]">
              <Slider {...settings} className="client_slider ">
                <div className="">
                  <img
                    className="lg:h-[200px] md:h-[200px] sm:h-[200px] h-[100px] "
                    src={Clientsliderimg1}
                    alt="Clientsliderimg1"
                  ></img>
                  <div className="ml-[20px] h-[12px] hidden md:block lg:block sm:hidden w-[125px]  rounded-b-lg opacity-70  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </div>

                <div>
                  <img
                    src={Clientsliderimg2}
                    alt="Clientsliderimg2"
                    className="lg:h-[200px] md:h-[200px] sm:h-[200px] h-[100px]"
                  ></img>
                  <div className="ml-[20px] h-[12px] hidden md:block lg:block sm:hidden w-[125px]  rounded-b-lg opacity-70  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </div>
                <div>
                  <img
                    src={Clientsliderimg3}
                    alt="Clientsliderimg3"
                    className="lg:h-[200px] md:h-[200px] sm:h-[200px] h-[100px]"
                  ></img>
                  <div className="ml-[20px] h-[12px] hidden md:block lg:block sm:hidden w-[125px]  rounded-b-lg opacity-70  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </div>
                <div>
                  <img
                    src={Clientsliderimg4}
                    alt="Clientsliderimg4"
                    className="lg:h-[200px] md:h-[200px] sm:h-[200px] h-[100px]"
                  ></img>
                  <div className="ml-[20px] h-[12px]  w-[125px] hidden md:block lg:block sm:hidden rounded-b-lg opacity-70  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </div>
                <div>
                  <img
                    src={Clientsliderimg5}
                    alt="Clientsliderimg5"
                    className="lg:h-[200px] md:h-[200px] sm:h-[200px] h-[100px]"
                  ></img>
                  <div className=" hidden sm:block ml-[20px] hidden md:block lg:block sm:hidden h-[12px]  w-[125px]  rounded-b-lg opacity-70  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </div>
                <div>
                  <img
                    src={Clientsliderimg6}
                    alt="Clientsliderimg6"
                    className="lg:h-[200px] md:h-[200px] sm:h-[200px] h-[100px]"
                  ></img>
                  <div className="ml-[20px] h-[12px]  w-[125px] hidden md:block lg:block sm:hidden rounded-b-lg opacity-70  bg-gradient-to-t from-[#07B1FC] to-[#00B1FF00]"></div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="flex lg:inline-flex md:inline-flex sm:inline-flex hidden     justify-center items-center w-[347px] lg:border-l-[1px] lg:border-[#F9F9F963]">
          <div className="flex flex-col lg:ml-[20px]">
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90">
              07
            </h1>
            <div className="bg-[#627D89] h-[196px] w-[7px] rounded-lg ">
              <div className="bg-[#00B1FF] h-[140px] w-full rounded-lg "></div>
            </div>
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90s  transform rotate-90">
              05
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Client