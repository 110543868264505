import React from "react";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import AddService from "../../components/admin/service/AddService";
import { useLocation } from "react-router-dom";

function EditServicePage() {
  const location = useLocation();
  const data = location.state?.editData;
  return (
    <div className="main-wrapper">
    <Header/>

    <Sidebar />
    <div className="page-wrapper">
      <AddService data={data} />
      <Footer />
    </div>
  </div>
  )
}

export default EditServicePage