import axios from './Axios'

import { axiosPrivate } from './AxiosPrivate'

/* ----------------------------- super Admin requests ----------------------------- */

export const getDashboard = () => axiosPrivate.get(`/superAdmin-dashboard`)

export const viewServiceType = () => axiosPrivate.get(`/serviceType`)

export const addServiceType = (data) => axiosPrivate.post('/createServiceType',data)

export const editServiceType = (ID,data) =>  axiosPrivate.put(`/edit-serviceType/${ID}`,data)

export const deleteServiceType = (ID) =>  axiosPrivate.put(`/delete-serviceType/${ID}`)

export const getViewAdmins = () => axiosPrivate.get(`/view-admins`)

export const blockAdmin = (adminId) =>  axiosPrivate.put(`/block-admin/${adminId}`)

export const deleteAdmin = (adminId) =>  axiosPrivate.put(`/delete-admin/${adminId}`)

export const RestoreAdmin = (adminId) =>  axiosPrivate.put(`/restore-admin/${adminId}`)

export const getTashAdmins = () => axiosPrivate.get(`/view-trashAdmins`)

export const editAdmin = (adminId,adminData) =>  axiosPrivate.put(`/edit-admin/${adminId}`,adminData)

export const addAdmin = (adminData) => axiosPrivate.post('/createAdmin',adminData)

export const profileDetails = () =>  axiosPrivate.get('/superAdmin-profile')


export const changePassword =(pass,token) =>axiosPrivate.put(`/resetPassword`,{pass,token})

export const forgotPassword = (mail) => axios.post(`/forgotPassword`,mail)


/* -----------------------------  Admin requests ----------------------------- */

export const getAdminprofile = () =>  axiosPrivate.get('/admin-profile')

export const getAdminDashboard = () => axiosPrivate.get(`/admin-dashboard`)

export const profileUpdate = (profile) =>  axiosPrivate.put('/admin-profileUpdate',profile)

export const getServiceType = () => axiosPrivate.get(`/getServiceType`)

export const addUser = (serviceId,userData) => axiosPrivate.post(`/createUser/${serviceId}`,userData)

export const getUser = () => axiosPrivate.get(`/view-user`)

export const getServiceManager = (serviceId) => axiosPrivate.get(`/view-service-manager/${serviceId}`)

export const editUser= (userId,userData) =>  axiosPrivate.put(`/edit-user/${userId}`,userData)

export const blockUser = (userId) =>  axiosPrivate.put(`/block-user/${userId}`)

export const deleteUser = (userId) =>  axiosPrivate.put(`/delete-user/${userId}`)

/* ------------------------------- Manager routes ------------------------------ */

export const getManagerDashboard = () => axiosPrivate.get(`/manager-dashboard`)

export const getManagerprofile = () =>  axiosPrivate.get('/user-profile')

export const managerProfileUpdate = (profile) =>  axiosPrivate.put('/user-profileUpdate',profile)

/* ------------------------------- auth routes ------------------------------ */

export const userSignup = (signupData) => axios.post('/signup',signupData)

export const userLogin = (loginData) => axios.post('/login',loginData)

// export const forgotPassword = (mail) => axios.post(`/forgotPassword`,mail)

export const updateNewPass =(pass,token)=>axios.put(`/resetPassword`,{pass,token})

export const userLogout = (refToken) => axios.post('/logout',{refToken})










