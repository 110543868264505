import React, { useState } from "react";
import Profile from "../../components/admin/profile/Profile";
import Header from "../../components/admin/header/Header";
import Sidebar from "../../components/admin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";

function AdminProfilePage() {
  const [trigger, setTrigger] = useState();
  return (
    <div className="main-wrapper">
      <Header trigger={trigger} />

      <Sidebar />
      <div className="page-wrapper">
        <Profile setTrigger={setTrigger} />
        <Footer />
      </div>
    </div>
  );
}

export default AdminProfilePage;
