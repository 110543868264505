import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsTags } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import PricingVector from "../../assets/websiteImg/PricingVector.svg";
import "../../assets/css/slick-slider.scss";
import { Link } from "react-router-dom";
 const Pricing = () => {
  const sliderSettings = {
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrowClass: "slick1",
  };

  return (
    <div className="flex">
      <div className="sidenav w-[107px] flex flex-col lg:p-[30px]    text-[13px] lg:border-r-[1px] lg:border-[#F9F9F963] uppercase font-normal tracking-normal text-left text-[#627D89] lg:items-center lg:gap-[80px] sm:gap-[5] lg:justify-center sm:justify-evenly justify-around  transform ">
        <a href="#" className=" transform -rotate-90">
          Whatsapp
        </a>
        <a href="#" className=" transform -rotate-90">
          Instagram
        </a>
        <a href="#" className=" transform -rotate-90">
          linkedin
        </a>
        <a href="#" className=" transform -rotate-90">
          Facebook
        </a>
      </div>
      <div className="md:container md:mx-auto mb-[57px] mt-[30px]">
        <div className=" flex-wrap ml-[20px] mr-[20px]  sm:flex md:flex lg:flex xl:flex ">
          <div className=" ">
            <div className=" ">
              <button className="text-white bg-[#D9D9D92E]  w-[146px] h-[42px] rounded-[45px] ">
                <div className="flex p-1 justify-center gap-2 text-[#62767F] font-[17px]">
                  <BsTags className="mt-[3px] text-[#62767F]" />
                  Pricing
                </div>
              </button>
              <div>
                <p className="text-[#00B1FF] text-[46px] mt-[5px] leading-[52px] font-semibold lg:w-[535px] sm:w-[535px] ms:w-[535px] w-[300px]">
                  Our Exclusive Pricing
                  <span className="text-[#ABABAB]"> for the corporates</span>
                </p>
              </div>
              <div>
                <p className="text-[#637780] md:w-[478px] lg:w-[478px] sm:w-[478px] w-[300px] lg:h-[200px] md:h-[85px] sm:h-[85px] h-[135px] mt-3 text-[15px] font-normal">
                Unlock unparalleled connectivity for your corporate ventures with our exclusive pricing plans. Tailored to meet the unique needs of businesses, On Cloud WiFi offers competitive rates that ensure seamless, secure, and reliable connectivity. Elevate your corporate network with our exclusive pricing benefits.
                </p>
                {/* <p className="text-[#637780] lg:w-[498px] md:w-[498px] sm:w-[498px]  w-[300px] lg:h-[70px] md:h-[70px] sm:h-[70px] h-[105px] text-[15px] font-normal">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p> */}
              </div>
              <div className="flex-wrap lg:flex md:flex sm:flex lg:gap-[60px] md:gap-[60px] sm:gap-[60px] gap-[30px]">
              <Link to="/Contact"><div>
                  <button className="text-white bg-[#07B1FC]  w-[211px] h-[55px] rounded-[27px] ">
                    <div className="flex  justify-center gap-2 text-[#031922]  font-medium">
                      <p>Contact for More</p>
                      <RxDotFilled className="text-[#031922] text-[25px]" />
                    </div>
                  </button>
                </div></Link>
                <div>
                  <p className="text-[#ABABAB] pt-[12px] text-[16px] lg:ml-[0px] md:ml-[0px] sm:ml-[0px] ml-[15px] font-medium underline">
                    Request for Quote
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[500px]  mt-[10px]">
            <Slider {...sliderSettings} className="slider">
              <div>
                <div className="mt-[10px] ">
                  <div className="lg:flex md:flex sm:flex gap-3 border-b-2   lg:w-[0px] md:w-[0px] sm:w-[0px] w-[250px] pb-2 border-[#163F51]">
                    <div className="flex lg:gap-0 md:gap-0 sm:gap-0 gap-2">
                      <p className="text-[#00B1FF]  text-[30px] font-semibold md:w-[276px] sm:w-[276px]">
                        Silver <span className="text-[#A7A7A7]">Package</span>{" "}
                      </p>
                      <img
                        src={PricingVector}
                        alt="pricingvector"
                        className="w-[50px] h-[50px] lg:mt-2 md:mt-2 sm:mt-2 mt-0 lg:ml-5 md:ml-5 sm:ml-5 ml-0"
                      ></img>
                    </div>
                    <div className="lg:ml-[10px] sm:ml-[50px]">
                      <p className="text-[#00B1FF] text-[10px] w-[162px] lg:text-right font-medium line-through">
                        AED 600/mo
                      </p>
                      <p className="text-[#00B1FF] text-[20px] lg:text-right font-semibold ">
                        AED 600/mo
                      </p>
                    </div>
                  </div>
                  <div>
                    <ul className="lg:list-disc    text-[#637780] text-[16px] font-normal ">
                      <div className="border-b-2 border-[#163F51] lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px] ">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Other text Title
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Description Space
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-[10px] ">
                  <div className="lg:flex md:flex sm:flex gap-3 border-b-2  lg:w-[0px] md:w-[0px] sm:w-[0px] w-[250px]   pb-2 border-[#163F51]">
                    <div className="flex lg:gap-0 md:gap-0 sm:gap-0 gap-2">
                      <p className="text-[#00B1FF]  text-[30px] font-semibold md:w-[276px] sm:w-[276px]">
                        Gold <span className="text-[#A7A7A7]">Package</span>{" "}
                      </p>
                      <img
                        src={PricingVector}
                        alt="pricingvector"
                        className="w-[50px] h-[50px] lg:mt-2 md:mt-2 sm:mt-2 mt-0 lg:ml-5 md:ml-5 sm:ml-5 ml-0"
                      ></img>
                    </div>
                    <div className="lg:ml-[10px] sm:ml-[50px]">
                      <p className="text-[#00B1FF] text-[10px] w-[162px] lg:text-right font-medium line-through">
                        AED 900/mo
                      </p>
                      <p className="text-[#00B1FF] text-[20px] lg:text-right font-semibold ">
                        AED 799/mo
                      </p>
                    </div>
                  </div>
                  <div>
                    <ul className="lg:list-disc    text-[#637780] text-[16px] font-normal ">
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Other text Title
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Description Space
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div>
                <div className="mt-[10px] ">
                <div className="lg:flex md:flex sm:flex gap-3 border-b-2  lg:w-[0px] md:w-[0px] sm:w-[0px] w-[250px]   pb-2 border-[#163F51]">
                    <div className="flex lg:gap-0 md:gap-0 sm:gap-0 gap-2">
                      <p className="text-[#00B1FF]  text-[30px] font-semibold md:w-[276px] sm:w-[276px]">
                        Platinum<span className="text-[#A7A7A7]"> Package</span>{" "}
                      </p>
                      <img
                        src={PricingVector}
                        alt="pricingvector"
                        className="w-[50px] h-[50px]lg:mt-2 md:mt-2 sm:mt-2 mt-0 lg:ml-5 md:ml-5 sm:ml-5 ml-0"
                      ></img>
                    </div>
                    <div className="lg:ml-[10px] sm:ml-[60px]">
                      <p className="text-[#00B1FF] text-[10px] w-[162px] lg:text-right font-medium line-through">
                        AED 1200/mo
                      </p>
                      <p className="text-[#00B1FF] text-[20px] lg:text-right font-semibold ">
                        AED 1099/mo
                      </p>
                    </div>
                  </div>
                  <div>
                    <ul className="lg:list-disc    text-[#637780] text-[16px] font-normal ">
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Other text Title
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Description Space
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2 border-[#163F51]  lg:w-full md:w-full sm:w-full w-[250px]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-[10px] ">
                  <div className="lg:flex md:flex sm:flex gap-3 border-b-2   lg:w-[0px] md:w-[0px] sm:w-[0px] w-[250px] pb-2 border-[#163F51]">
                    <div className="flex lg:gap-0 md:gap-0 sm:gap-0 gap-2">
                      <p className="text-[#00B1FF]  text-[30px] font-semibold md:w-[276px]">
                        Custom<span className="text-[#A7A7A7]"> Package</span>{" "}
                      </p>
                      <img
                        src={PricingVector}
                        alt="pricingvector"
                        className="w-[50px] h-[50px] lg:mt-2 md:mt-2 sm:mt-2 mt-0 lg:ml-5 md:ml-5 sm:ml-5 ml-0"
                      ></img>
                    </div>
                    <div className="lg:ml-[10px] sm:ml-[60px]">
                      <p className="text-[#00B1FF] text-[10px] w-[162px] lg:text-right font-medium line-through">
                        AED 1600+/mo
                      </p>
                      <p className="text-[#00B1FF] text-[20px] lg:text-right font-semibold ">
                        AED 1500+/mo
                      </p>
                    </div>
                  </div>
                  <div>
                    <ul className="lg:list-disc    text-[#637780] text-[16px] font-normal ">
                      <div className="border-b-2  lg:w-full md:w-full sm:w-full w-[250px] border-[#163F51]">
                        <li className=" p-[10px] ml-[60px]">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2  lg:w-full md:w-full sm:w-full w-[250px] border-[#163F51]">
                        <li className=" p-[10px] ml-[60px]">
                          Other text Title
                        </li>
                      </div>
                      <div className="border-b-2  lg:w-full md:w-full sm:w-full w-[250px] border-[#163F51]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                      <div className="border-b-2  lg:w-full md:w-full sm:w-full w-[250px] border-[#163F51]">
                        <li className=" p-[10px] ml-[60px]">
                          Description Space
                        </li>
                      </div>
                      <div className="border-b-2  lg:w-full md:w-full sm:w-full w-[250px] border-[#163F51]">
                        <li className=" p-[10px] ml-[60px]">
                          Sample Text Here
                        </li>
                      </div>
                      <div className="border-b-2  lg:w-full md:w-full sm:w-full w-[250px] border-[#163F51]">
                        <li className=" p-[10px] ml-[60px]">
                          Text Space Goes Here
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="flex lg:inline-flex md:inline-flex sm:inline-flex hidden   justify-center items-center w-[107px] lg:p-[32px]  lg:border-l-[1px] lg:border-[#F9F9F963]">
        <div className="flex flex-col lg:ml-[20px]   ">
          <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90">
            07
          </h1>
          <div className="bg-[#627D89] h-[196px] w-[7px] rounded-lg ">
            <div className="bg-[#00B1FF] h-[112px] w-full rounded-lg "></div>
          </div>
          <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90s  transform rotate-90">
            04
          </h1>
        </div>
      </div>
    </div>
  );
};


export default Pricing