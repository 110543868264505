import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineHome } from "react-icons/ai";
import oncloudwifiphone from "../../assets/websiteImg/oncloudwifiphone.png";
import "../../assets/css/slick-slider.scss";

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="flex relative">
        <div className="sidenav w-[107px]    m-0  lg:p-[30px] sidenav  flex flex-col text-[13px] lg:border-r-[1px] lg:border-[#F9F9F963]  uppercase font-normal tracking-normal  text-[#627D89] lg:items-center lg:gap-[80px] sm:gap-[5] lg:justify-center sm:justify-evenly justify-around  transform">
          <a href="#" className=" transform -rotate-90">
            Whatsapp
          </a>
          <a href="#" className=" transform -rotate-90">
            Instagram
          </a>
          <a href="#" className=" transform -rotate-90">
            linkedin
          </a>
          <a href="#" className=" transform -rotate-90">
            Facebook
          </a>
        </div>
        <div className="container  mx-auto mt-[30px]">
          <Slider {...settings}>
            <div className="">
              <div className="lg:flex lg:justify-center">
                <div>
                  <div className="">
                    <button className="text-white bg-[#D9D9D92E] w-[146px] h-[42px] rounded-[45px] ">
                      <div className="flex p-1 justify-center items-center gap-3 text-[#62767F] font-[17px]">
                        <AiOutlineHome />
                        Home
                      </div>
                    </button>
                  </div>

                  <div>
                    <p className="text-[#ABABAB] md:text-[60px] text-[40px]  mt-[20px] font-bold leading-[72px] lg:w-[427px] md:w-[477px] sm:w-[377px] w-[350px]">
                      Welcome to
                      <span className="text-[#07B1FC]"> On Cloud Wifi </span>
                    </p>
                  </div>
                  <div>
                    <p className="text-[#637780] font-[19px]  lg:w-[536px] md:-w-[586px] sm:w-[526px] w-[320px] leading-normal">
                    At On Cloud WiFi, we believe in transforming the way you connect. Say goodbye to connectivity hassles and hello to a world of fast, reliable, and secure WiFi access. We specialize in providing hassle-free WiFi logins and efficient consumer data management, ensuring that you and your customers stay seamlessly connected.
                    </p>
                    <p className="w-[98px] h-[4px] bg-[#07B1FC] mt-[20px] shrink-0 rounded-[12px] "></p>
                  </div>
                  <div className="flex gap-[40px] mt-[10px]">
                    <div className="">
                      <p className="bg-gradient-to-r from-[#ABABAB]  to-[#00B1FF] text-transparent bg-clip-text font-bold text-[47px]">
                        1k+
                      </p>
                      <p className="bg-gradient-to-r from-[#ABABAB] to-[#00B1FF] text-transparent bg-clip-text  font-bold">
                        Clients
                      </p>
                    </div>
                    <div>
                      <p className="bg-gradient-to-r from-[#ABABAB]  to-[#00B1FF] text-transparent bg-clip-text font-bold text-[47px]">
                        10k+
                      </p>
                      <p className="bg-gradient-to-r from-[#ABABAB] to-[#00B1FF] text-transparent bg-clip-text  font-bold">
                        number of users
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <img
                    className="object-contain md:h-[430px]  lg:w-[559px] sm:w-[609px] w-[309px] md:w-[609px]"
                    alt="homepage"
                    src={oncloudwifiphone}
                  ></img>
                </div>
              </div>
            </div>
            {/* <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div> */}
          </Slider>
        </div>
        <div className="flex lg:inline-flex md:inline-flex sm:inline-flex     justify-center items-center w-[115px] lg:p-[30px] lg:border-l-[1px] lg:border-[#F9F9F963]">
          <div className="flex flex-col lg:ml-[20px]">
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF] transform rotate-90">
              07
            </h1>
            <div className="bg-[#627D89] h-[196px] w-[7px] rounded-lg ">
              <div className="bg-[#00B1FF] h-[28px] w-full rounded-lg "></div>
            </div>
            <h1 className="text-[20px] pt-4 font-semibold leading-[25px] text-left text-[#FFFFFF]  rotate-90s  transform rotate-90">
              01
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
