import React from 'react'
import Profile from '../../components/superAdmin/profile/Profile'
import Header from '../../components/header/Header';
import Sidebar from '../../components/superAdmin/sidebar/SideBar';
import Footer from '../../components/footer/Footer';

function ProfilePage() {
  return (
    <div className="main-wrapper">
      <Header sprAdmin={true} />

      <Sidebar />
      <div className="page-wrapper">
        <Profile />
        <Footer />
      </div>
    </div>
  );
}

export default ProfilePage