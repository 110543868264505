import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Badge, Modal, Table, Tooltip, Typography } from "antd";
import { onShowSizeChange, itemRender } from "../../Pagination";
import { AiOutlineDelete, AiOutlineUserAdd } from "react-icons/ai";
import { FaUsersViewfinder } from "react-icons/fa6";
import { FiEye } from "react-icons/fi";
import { CgBlock, CgUnblock } from "react-icons/cg";
import { LiaUserEditSolid } from "react-icons/lia";
import ErrorLogout from "../../../helper/ErrorLogout";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { blockService, deleteService, getViewService } from "../../../api/ServiceRequest";
import { MdOutlineAddBox } from "react-icons/md";
import moment from "moment";
const { confirm } = Modal;
const { Text } = Typography;
const ServiceList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [service, setService] = useState([]);
  const [loader, setLoader] = useState(true);
  const [trigger, setTrigger] = useState();
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const getService = async () => {
      try {
        const { data } = await getViewService();
        setService(data?.ServiceList);
        setLoader(false);
      } catch (error) {
        console.log(error.message);
        if (error?.response?.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    getService();
  }, [trigger]);


  const handleGuestView = (serviceID) => {
    navigate("/admin/serviceGuest", { state: { serviceID } });
  };
  const handleViewManager = (serviceID) => {
    navigate("/admin/managerList", { state: { serviceID } });
  };
  const handleAddUser = (serviceID) => {
  
    navigate("/admin/addUser", { state: { serviceID } });
  };
  const handleEdit = (editData) => {
  
    navigate("/admin/editService", { state: { editData } });
  };

  const handleBlock = async (id) => {
    confirm({
      title: `${
        id?.status === "deactive"
          ? "Do you Want to Unblock this Service "
          : "Do you Want to Block this Service"
      }`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const block = async () => {
          try {
            const { data } = await blockService(id._id);
            if (data.update) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        block();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'blue',
          borderColor: 'blue',
          color: 'white', // Text color
        },
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id) => {
    confirm({
      title: "Do you Want to Delete this Service",
      icon: <ExclamationCircleFilled />,
      content:
        "if you delete this Service it will effect all the users under this Service.",
      onOk() {
        const deleteCall = async () => {
          try {
            const { data } = await deleteService(id._id);
            if (data.delete) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        deleteCall();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          borderColor: 'red',
          color: 'white', // Text color
        },},
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const column = [
    {
      title: "Sl.No",
      dataIndex: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      render: (domain) => {
       
        return (
         <Link  to={`/${domain}`} target="_blank">{domain}</Link>
        );
      },
     
      
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      align: 'center',
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      render: (createdDate) => {
       
        return (
       <>{moment(createdDate).format("YYYY-MM-DD")}</>
        );
      },
    },
    {
      title: "Guest View",
      dataIndex: "status._id",
      align: 'center',
      render: (status, _id) => (
        <>
          <div className="actions gap-2">
          <Tooltip title="View Guest ">
          <FaUsersViewfinder    className="cursor-pointer" color={"green"} size={24} onClick={() => handleGuestView(_id._id)}/>
              </Tooltip>
           
           
          </div>
        </>
      ),
    },
    {
      title: "Manager View",
      dataIndex: "status._id",
      align: 'center',
      render: (status, _id) => (
        <>
          <div className="actions gap-2 ">
          <Tooltip title="Add Manager">
          <MdOutlineAddBox  className="cursor-pointer"  color={"blue"} size={24} onClick={() => handleAddUser(_id._id)} />
              </Tooltip>
          <Tooltip title="View Manager">
          <FiEye   className="cursor-pointer" color={"blue"} size={24}  onClick={() => handleViewManager(_id._id)}/>
              </Tooltip>
              <Badge count={_id?.managerList?.length?_id?.managerList?.length:"O"} color='#faad14' />
           
          </div>
        </>
      ),
    },
    
   
    {
      title: "Action",
      dataIndex: "status._id",
      align: 'center',
      render: (status, _id) => (
        <>
          <div className="actions">
           
          <Tooltip title="Edit service">
              <LiaUserEditSolid className="cursor-pointer" size={20} onClick={() => handleEdit(_id)} />
              </Tooltip>
           
              {_id.status == "active" ? (
                <Tooltip title="block service">
                  {" "}
                  <CgBlock className="cursor-pointer" color={"green"} size={20} onClick={() => handleBlock(_id)} />{" "}
                </Tooltip>
              ) : (
                <Tooltip title="unblock service">
                  <CgUnblock className="cursor-pointer" color={"#faad14"} size={20} onClick={() => handleBlock(_id)} />
                </Tooltip>
              )}
           
         
              <Tooltip title="delete service">
                <AiOutlineDelete  className="cursor-pointer" color={"red"} size={20} onClick={() => handleDelete(_id)} />
              </Tooltip>
           
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Service List</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Service List</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <Table
                class="table table-stripped table-hover datatable"
                pagination={{
                  total: service?.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                columns={column}
                loading={loader}
                dataSource={service}
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceList;
