import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, Form, Input, Modal, Table, Tooltip, Typography } from "antd";
import { onShowSizeChange, itemRender } from "../../Pagination";
import {
  addServiceType,
  deleteServiceType,
  editServiceType,
  viewServiceType,
} from "../../../api/AdminRequest";
import ErrorLogout from "../../../helper/ErrorLogout";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineDelete } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { LiaUserEditSolid } from "react-icons/lia";
const { confirm } = Modal;
const { Text } = Typography;
const ServiceTypeList = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState("");
  const showModal = () => {
    setOpen(true);
  };
  const handleEdit = (editData) => {
    setEditData(editData)
    form.setFieldsValue({
      name: editData?.name,
    });
    setOpen(true);
   
  };
  const handleCancel = () => {
    form.setFieldsValue({
      name: '',
    });
    setEditData('')
    setOpen(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const serviceTypeCall = async () => {
      try {
        const { data } = await viewServiceType();
        setServiceType(data.serviceType);
        setLoader(false);
      } catch (error) {
        console.log(error.message);
        if (error.response.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    serviceTypeCall();
  }, [trigger]);

  const handleSubmit = async (serviceType) => {
    try {
      setLoading(true);
      const { data } = editData
        ? await editServiceType(editData?._id, serviceType)
        : await addServiceType(serviceType);
      if (data?.success) {
        setLoading(false);
        setTrigger(Date.now());
        setOpen(false);
        toast.success("Service Type Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/superAdmin/serviceType");
        }, 2500);
      } else if (data?.update) {
        setLoading(false);
        setTrigger(Date.now());
        setOpen(false);
        toast.success("Service Type Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/superAdmin/serviceType");
        }, 2500);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast(error.message);
      if (error.response?.data?.message) {
        toast(error?.response?.data?.message);
      }
    }
  };

  const handleDelete = async (id) => {
    confirm({
      title: "Do you Want to Delete this Service Type",
      icon: <ExclamationCircleFilled />,
      content:
        "if you delete this  Service Type new Service can't get this one .",
      onOk() {
        const deleteCall = async () => {
          try {
            const { data } = await deleteServiceType(id._id);
            if (data.delete) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        deleteCall();
      },
      okButtonProps: {
        style: {
          backgroundColor: "red",
          borderColor: "red",
          color: "white", // Text color
        },
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const column = [
    {
      title: "Sl.No",
      dataIndex: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Service Count",
      dataIndex: "serviceCount",
    },
    {
      title: "Action",
      dataIndex: "status._id",
      render: (status, _id) => (
        <>
          <div className="actions justify-start gap-2">
            
            <Tooltip title="delete">
              <LiaUserEditSolid className="cursor-pointer" color="#faad14" size={20} onClick={() => handleEdit(_id)} />
            </Tooltip>
            <Tooltip title="delete">
              <AiOutlineDelete className="cursor-pointer" color="red" size={20} onClick={() => handleDelete(_id)} />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Service Type List</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/superAdmin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Service Type List</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    {/* <h3 className="page-title">User</h3> */}
                  </div>
                  <div className="col-auto text-end float-end ms-auto download-grp">
                    {/* <Link to="#" className="btn btn-outline-primary me-2">
                                                        <i className="fas fa-download" /> Download
                                                    </Link> */}
                                                     <Tooltip title="Add Service Type">
                    <div onClick={showModal} className="btn btn-primary">
                      <i className="fas fa-plus" />
                      <BiPlus size={24} />
                    </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <Table
                class="table table-stripped table-hover datatable"
                pagination={{
                  total: serviceType.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                columns={column}
                loading={loader}
                dataSource={serviceType}
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        <Modal
          open={open}
          title="Add Service Type"
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <Form
            className="relative"
            onFinish={handleSubmit}
            form={form}
          >
            <div className="row">
              <div className="col-12 col-sm-8">
                <label>
                  Name <span className="login-danger">*</span>
                </label>
                <Form.Item
                
                  name="name"
                  rules={[
                    {
                      type: "text",
                      message: "The input is not valid Service Type",
                    },
                    {
                      required: true,
                      message: "Please Enter Service Type!",
                    },
                  ]}
                >
                  <Input  className="h-10" placeholder="Service Type" />
                </Form.Item>
              </div>

              <div className="col-12 col-sm-4 mt-8">
                <Button
                  className="w-full h-10 rounded-md text-white bg-sky-700"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ServiceTypeList;
