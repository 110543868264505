
import { axiosPrivate } from './AxiosPrivate'




/* ----------------------------- Service requests ----------------------------- */

export const getViewService = () => axiosPrivate.get(`/view-service`)

export const getAdminService = (adminID) => axiosPrivate.get(`/view-adminservice/${adminID}`)

export const addService = (serviceData) => axiosPrivate.post('/create-service',serviceData)

export const editService= (serviceId,serviceData) =>  axiosPrivate.put(`/edit-service/${serviceId}`,serviceData)

export const blockService = (serviceId) =>  axiosPrivate.put(`/block-service/${serviceId}`)

export const deleteService = (serviceId) =>  axiosPrivate.put(`/delete-service/${serviceId}`)

export const getTrashService = () => axiosPrivate.get(`/view-trashservice`)

export const RestoreService= (serviceId) =>  axiosPrivate.put(`/restore-service/${serviceId}`)
















