import React, { useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";

const Modal = ({ isOpen, onClose }) => {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    onClose(); // Close the modal after clicking a link
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="bgimg fixed z-50 w-full h-full flex    shadow-lg">
      <div className="container mx-auto p-[10px]">
        <div className="flex justify-between">
          <div className="w-1/6 p-[80px]">
            <h1 className="text-white text-2xl"></h1>
            <ul className="text-[35px] font-semibold cursor-pointer leading-[55px] text-left">
              <Link to="/" onClick={() => handleLinkClick("/")}>
                <li
            className={`${
              activeLink === "/" ? "text-[#00B1FF] !important" : "text-white"
            } border-b  w-[384px] border-[#ABABAB] mb-1`}
                >
                  Home
                </li>
              </Link>
              <Link to="/about" onClick={() => handleLinkClick("about")}>
              <li
            className={`${
              activeLink === "about" ? "text-[#00B1FF] !important" : "text-white"
            } border-b  w-[384px] border-[#ABABAB] mb-1`}
                >
                  About
                </li>
              </Link>
                  <Link to="/features" onClick={() => handleLinkClick("features")}>
                  <li
            className={`${
              activeLink === "features" ? "text-[#00B1FF] !important" : "text-white"
            } border-b  w-[384px] border-[#ABABAB] mb-1`}
                >
                  Features
                </li>
              </Link>
              <Link to="/pricing" onClick={() => handleLinkClick("pricing")}>
              <li
            className={`${
              activeLink === "pricing" ? "text-[#00B1FF] !important" : "text-white"
            } border-b  w-[384px] border-[#ABABAB] mb-1`}
                >
                  Pricing
                </li>
              </Link>
              <Link to="/clients" onClick={() => handleLinkClick("clients")}>
              <li
            className={`${
              activeLink === "clients" ? "text-[#00B1FF] !important" : "text-white"
            } border-b  w-[384px] border-[#ABABAB] mb-1`}
                >
                  Clients
                </li>
              </Link>
              <Link to="/works" onClick={() => handleLinkClick("works")}>
              <li
            className={`${
              activeLink === "works" ? "text-[#00B1FF] !important" : "text-white"
            } border-b  w-[384px] border-[#ABABAB] mb-1`}
                >
                  How Works
                </li>
              </Link>
              <Link to="/contact" onClick={() => handleLinkClick("contact")}>
              <li
            className={`${
              activeLink === "contact" ? "text-[#00B1FF] !important" : "text-white"
            }   w-[384px]  mb-1`}
                >
                  Contact Now
                </li>
              </Link>
              {/* Add similar logic for other links */}
            </ul>
          </div>
          <div className=" flex  justify-end">
            <button
              className="text-[#D9D9D9] text-[30px] h-[10px] rounded-md"
              onClick={onClose}
            >
              <TfiClose />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
